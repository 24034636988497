import React from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Button, Header, Icon, Modal } from 'semantic-ui-react'

import Chat from './'
import store from '../../store/configureStore'
class ModalChat extends React.PureComponent {
  static contextTypes = { gettext: PropTypes.func.isRequired }

  static propTypes = {}

  constructor(props, context) {
    super(props, context)
  }

  render() {
    const params = new URLSearchParams(window.location.search)

    const flow = params.get('flow')

    return (
      <Modal
        trigger={
          <Button size={this.props.buttonSize || 'huge'}>
            {this.props.buttonText}
          </Button>
        }
        basic
        size="tiny"
        closeIcon

        // defaultOpen
        // style={{ backgroundColor: 'red' }}
      >
        <Modal.Content>
          <div style={{ minHeight: 900 }}>
            <Chat
              goalFlow={this.props.goalFlow}
              step={this.props.step}
              store={store}
            />
          </div>
        </Modal.Content>
      </Modal>
    )
  }
}

export default connect(
  state => ({
    login: state.core.auth.login,
    credentials: state.global.credentials
  }),
  dispatch => ({
    actions: Object.assign({}, { dispatch }, bindActionCreators({}, dispatch))
  })
)(ModalChat)

// function Header(props) {
//   return (
//     <div className="main-heading">
//       <h1>
//         Betal for strømmen du bruker, og <strong>ikke noe mer</strong>
//       </h1>
//     </div>
//   )
// }
const CustomHeader = () => {
  return (
    <div className="main-heading">
      <h1>
        Velkommen til <strong>Hudya!</strong>
      </h1>
    </div>
  )
}
