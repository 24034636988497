import React from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Grid, Container } from 'semantic-ui-react'
import ReactMarkdown from 'react-markdown/with-html'
import { legalEntityAPI } from '../../../store/webapi/legalEntity'

import { setCredentials } from '../../../store/global/credentials'
import styles from './aconto.module.css'
import faq from './faq'

import jwtDecode from 'jwt-decode'

import Chat from '../../../components/Chat'
import ProductLinks from '../components/ProductLinks'
import ChatContainer from '../../../components/ChatContainer'

class Aconto extends React.PureComponent {
  static contextTypes = {
    gettext: PropTypes.func.isRequired,
    xgettext: PropTypes.func.isRequired,
  }

  static propTypes = {}

  constructor(props, context) {
    super(props, context)
    this.logUserIn = this.logUserIn.bind(this)
    document.title = context.xgettext(
      'Refinansiering - Hudya',
      'Aconto page title'
    )
  }
  logUserIn(jwt) {
    if (!jwt) {
      return
    }
    this.props.actions.setCredentials(jwt)

    const decodedJWT = jwtDecode(jwt)

    this.props.actions.dispatch(
      //  accountAPI.actions.account.sync({ id: decodedJWT.sub })
      legalEntityAPI.actions.entity.sync({ id: decodedJWT.sub })
    )
  }
  render() {
    const { gettext, xgettext } = this.context
    const params = new URLSearchParams(window.location.search)
    const flow = params.get('flow') || 'debt_check'

    const step = 'skip_start'
    const chatH1 = xgettext(
      '<strong>Refinansier</strong> dine forbrukslån med Aconto',
      'refinancing main header'
    )
    const chatH2 = gettext(
      'Aconto er en tjeneste eid av Hudya som har spesialisert seg på refinansiering i samarbeid med 18 banker.'
    )
    const chatSideBody = gettext(
      'Hver enkelt bank har sin egen prismodell. Prisene vil variere mellom bankene og fra kunde til kunde.  <br /> Pris.eksempel: 65.000,- over 5 år, effektiv rente 14,0%, tot.kost 89.093,- <br />Laveste nominelle rente 5,7%. Høyeste rente 22%'
    )

    return (
      <div className={styles['public-aconto']}>
        <ChatContainer
          flow={flow}
          step={step}
          chatH1={chatH1}
          chatH2={
            <p>
              <img
                style={{ height: '25px' }}
                src={process.env.PUBLIC_URL + '/assets/logo_aconto.svg'}
              />{' '}
              <i>{gettext('is a part of Hudya')}</i>
            </p>
          }
          chatSideBody={chatSideBody}
        />

        <ProductLinks />
        <div className="aconto-faq">
          <Container>
            <div className="svg-curve">
              <svg
                version="1.1"
                fill="#FFFFF7"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                viewBox="0 0 1080 65"
                preserveAspectRatio="none"
              >
                <path d="M0,45.069C671.161,45.478,1080,0,1080,0v65H0V45.069z" />
              </svg>
            </div>
            <Grid stackable>
              <Grid.Row textAlign="left">
                <Grid.Column width="7">
                  <h3 className="section-title">
                    <ReactMarkdown
                      source={xgettext(
                        'All you need to know about <strong>refinancing</strong>',
                        'refinancing faq header'
                      )}
                      escapeHtml={false}
                    />
                  </h3>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row textAlign="left">
                {faq.map((f) => {
                  return <Faq faq={f} />
                })}
              </Grid.Row>
            </Grid>
          </Container>
        </div>
      </div>
    )
  }
}

export default connect(
  (state) => ({
    credentials: state.global.credentials,
  }),
  (dispatch) => ({
    actions: Object.assign(
      {},
      { dispatch },
      bindActionCreators({ setCredentials }, dispatch)
    ),
  })
)(Aconto)
function Faq(props) {
  return (
    <Grid.Column width="8">
      <a href={props.faq.url}>{props.faq.name}</a>
    </Grid.Column>
  )
}
