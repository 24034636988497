import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Button, Card, Grid, Modal, Header, Loader } from 'semantic-ui-react';

import style from '../overview.module.css';
import Chat from '../../../components/Chat';
import store from '../../../store/configureStore';

import styles from './styles.module.scss';

class ProductCard extends React.Component {
  static contextTypes = {
    xgettext: PropTypes.func.isRequired,
    gettext: PropTypes.func.isRequired,
  };

  render() {
    const {
      config,
      disabled,
      loginUri,
      header,
      desc,
      price,
      priceTopText,
      priceBottomText,
      flow,
      orderButtonText,
      flowStep,
      readMoreUri,
      readMore,
      loading,
    } = this.props;
    const { defaultLocale } = config;

    return loading ? (
      <Card fluid className="product-card">
        <div className={`content ${styles.productCardLoader}`}>
          <Loader
            className={styles.loader}
            active
            size="medium"
            color="white"
          />
        </div>
      </Card>
    ) : (
      <Card
        fluid
        className={[
          style['product-card'],
          disabled ? style['product-card-disabled'] : '',
        ].join(' ')}
      >
        {loginUri && defaultLocale === 'nb_NO' && (
          <div className="ribbon-wrapper-green">
            <div className="ribbon-green">
              <a
                href={loginUri}
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: 'white' }}
              >
                Log in
              </a>
            </div>
          </div>
        )}
        <div className="content" style={{ minHeight: 430, maxHeight: 430 }}>
          <h2 className={style['product-card-title']}>{header}</h2>
          <Grid>
            <Grid.Row
              style={{
                marginBottom: '2em',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Grid.Column width="16">
                <p className={style['product-card-info']}>{desc}</p>
              </Grid.Column>
            </Grid.Row>
          </Grid>

          {!disabled && (
            <span>
              <small>{priceTopText}</small>
              <p className={style['product-card-price']}>{price}</p>
              <p className={style['product-card-price-detail']}>
                <small>{priceBottomText}</small>
              </p>
            </span>
          )}

          {flow && (
            <Modal
              trigger={
                <Button
                  disabled={disabled}
                  size="big"
                  style={{
                    color: '#ffffff',
                    fontWeight: 600,
                    borderRadius: 30,
                    marginBottom: 15,
                    background:
                      'linear-gradient(225deg, #fb637e 0%, #ff6900 100%)',
                  }}
                >
                  {orderButtonText}
                </Button>
              }
              basic
              size="tiny"
              closeIcon
              // defaultOpen
            >
              <Header />
              <Modal.Content style={{ minHeight: 1500 }}>
                <div style={{ minHeight: 900 }}>
                  <Chat goalFlow={flow} step={flowStep} store={store} />
                </div>
              </Modal.Content>
            </Modal>
          )}
          {!flow && (
            <Button
              as="a"
              disabled={disabled}
              href={readMoreUri}
              size="big"
              style={{
                color: '#ffffff',
                fontWeight: 600,
                borderRadius: 30,
                marginBottom: 15,
                background: '#3f2a56',
              }}
            >
              {orderButtonText}
            </Button>
          )}
          <div style={{ minHeight: 23 }}>
            <a
              hidden={disabled}
              href={readMoreUri}
              className={style['link-to-product']}
            >
              {readMore}
            </a>
          </div>
        </div>
      </Card>
    );
  }
}
export default withRouter(
  connect(
    (state) => ({
      config: state.global.config,
    }),
    (dispatch) => ({
      actions: Object.assign(
        {},
        { dispatch },
        bindActionCreators({}, dispatch),
      ),
    }),
  )(ProductCard),
);

ProductCard.propTypes = {
  config: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  loginUri: PropTypes.string,
  header: PropTypes.string,
  desc: PropTypes.string,
  price: PropTypes.any.isRequired,
  priceTopText: PropTypes.string,
  priceBottomText: PropTypes.string,
  flow: PropTypes.string,
  orderButtonText: PropTypes.string,
  flowStep: PropTypes.string,
  readMoreUri: PropTypes.string,
  readMore: PropTypes.string,
  loading: PropTypes.bool,
};
ProductCard.defaultProps = {
  disabled: false,
  loginUri: null,
  header: '',
  desc: '',
  priceTopText: '',
  priceBottomText: '',
  flow: '',
  orderButtonText: '',
  flowStep: 'init',
  readMoreUri: '',
  readMore: '',
  loading: false,
};
