import React from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Grid, Container } from 'semantic-ui-react'

import ReactMarkdown from 'react-markdown/with-html'

import { setCredentials } from '../../../store/global/credentials'
import styles from './mobile.module.css'

import faq from './faq'
import Chat from '../../../components/Chat'
import ProductLinks from '../components/ProductLinks'
import ChatContainer from '../../../components/ChatContainer'

class MobileProduct extends React.PureComponent {
  static contextTypes = {
    gettext: PropTypes.func.isRequired,
    xgettext: PropTypes.func.isRequired,
  }

  static propTypes = {}

  constructor(props, context) {
    super(props, context)

    document.title = context.xgettext('Mobil - Hudya', 'Mobile page title')
  }

  render() {
    const params = new URLSearchParams(window.location.search)
    const { gettext, xgettext } = this.context
    const flow = params.get('flow')
    const productIncludesStatements = [
      gettext('Telenor 4G'),
      gettext('No binding'),
      gettext('Use in EU'),
      gettext('Free BankID'),
      gettext('Free speed'),
    ]
    let product = document.location.pathname.replace(/abonnement/g, '')

    if (product.charAt(product.length - 1) !== '/') product = product + '/'
    const productTexts = {
      '/mobil/flex/': {
        heading: gettext('Hudya <strong>Mobil</strong> flex'),
        h2: gettext('flex side heading'),
        h2content: gettext('flex si‚de content'),
        subscriptionValue: 'FLEX_ZERO',
        firstChatMessage: xgettext(
          'Flex Zero First Message',
          'first message in mobile flow'
        ),
      },
      '/mobil/0mb/': {
        heading: gettext('Hudya <strong>Mobil</strong> 0GB'),
        h2: gettext('0gb side heading'),
        h2content: gettext('0gb side content'),
        subscriptionValue: '39_KR_MND',
        firstChatMessage: xgettext(
          '0 gb First Message',
          'first message in mobile flow'
        ),
      },
      '/mobil/300mb/': {
        heading: gettext('Hudya <strong>Mobil</strong> 300MB'),
        h2: gettext('300MB side heading'),
        h2content: gettext('300MB side content'),
        subscriptionValue: '300_MB_DATA',
        firstChatMessage: xgettext(
          '300mb First Message',
          'first message in mobile flow'
        ),
      },
      '/mobil/500mb/': {
        heading: gettext('Hudya <strong>Mobil</strong> 500MB'),
        h2: gettext('500MB side heading'),
        h2content: gettext('500MB side content'),
        subscriptionValue: '500_MB_DATA',
        firstChatMessage: xgettext(
          '500mb First Message',
          'first message in mobile flow'
        ),
      },
      '/mobil/barn-1gb/': {
        heading: gettext('Hudya <strong>Mobil</strong> barn-1gb'),
        h2: gettext('barn-1gb side heading'),
        h2content: gettext('barn-1gb side content'),
        subscriptionValue: '1_GB_BARN',
        firstChatMessage: xgettext(
          '1 gb barn First Message',
          'first message in mobile flow'
        ),
      },
      '/mobil/1gb/': {
        heading: gettext('Hudya <strong>Mobil</strong> 1GB'),
        h2: gettext('1gb side heading'),
        h2content: gettext('1gb side content'),
        subscriptionValue: '1_GB_DATA',
        firstChatMessage: xgettext(
          '1 gb First Message',
          'first message in mobile flow'
        ),
      },
      '/mobil/2gb/': {
        heading: gettext('Hudya <strong>Mobil</strong> 2GB'),
        h2: gettext('2gb side heading'),
        h2content: gettext('2gb side content'),
        subscriptionValue: '2_GB_DATA',
        firstChatMessage: xgettext(
          '2 gb First Message',
          'first message in mobile flow'
        ),
      },
      '/mobil/3gb/': {
        heading: gettext('Hudya <strong>Mobil</strong> 3GB'),
        h2: gettext('3gb side heading'),
        h2content: gettext('3gb side content'),
        subscriptionValue: '3_GB_DATA',
        firstChatMessage: xgettext(
          '3 gb First Message',
          'first message in mobile flow'
        ),
      },
      '/mobil/5gb/': {
        heading: gettext('Hudya <strong>Mobil</strong> 5GB'),
        h2: gettext('5gb side heading'),
        h2content: gettext('5gb side content'),
        subscriptionValue: '5_GB_DATA',
        firstChatMessage: xgettext(
          '5 gb First Message',
          'first message in mobile flow'
        ),
      },
      '/mobil/6gb/': {
        heading: gettext('Hudya <strong>Mobil</strong> 6GB'),
        h2: gettext('6gb side heading'),
        h2content: gettext('6gb side content'),
        subscriptionValue: '6_GB_DATA',
        firstChatMessage: xgettext(
          '6 gb First Message',
          'first message in mobile flow'
        ),
      },
      '/mobil/10gb/': {
        heading: gettext('Hudya <strong>Mobil</strong> 10GB'),
        h2: gettext('10gb side heading'),
        h2content: gettext('10gb side content'),
        subscriptionValue: '10_GB_DATA',
        firstChatMessage: xgettext(
          '10 gb First Message',
          'first message in mobile flow'
        ),
      },
      '/mobil/12gb/': {
        heading: gettext('Hudya <strong>Mobil</strong> 12GB'),
        h2: gettext('12gb side heading'),
        h2content: gettext('12gb side content'),
        subscriptionValue: '12_GB_DATA',
        firstChatMessage: xgettext(
          '12 gb First Message',
          'first message in mobile flow'
        ),
      },
      '/mobil/20gb/': {
        heading: gettext('Hudya <strong>Mobil</strong> 20GB'),
        h2: gettext('20gb side heading'),
        h2content: gettext('20gb side content'),
        subscriptionValue: '20_GB_DATA',
        firstChatMessage: xgettext(
          '20 gb First Message',
          'first message in mobile flow'
        ),
      },
      '/mobil/30gb/': {
        heading: gettext('Hudya <strong>Mobil</strong> 30GB'),
        h2: gettext('30gb side heading'),
        h2content: gettext('30gb side content'),
        subscriptionValue: '30_GB_DATA',
        firstChatMessage: xgettext(
          '30 gb First Message',
          'first message in mobile flow'
        ),
      },
      '/mobil/mobilt-bredband/': {
        heading: gettext('Hudya <strong>mobilt bredbånd</strong>  '),
        h2: gettext('mobilt bredbånd side heading'),
        h2content: gettext('mobilt bredbånd side content'),
        subscriptionValue: 'DATA_MOBILE',
        firstChatMessage: xgettext(
          'mobilt bredbånd First Message',
          'first message in mobile flow'
        ),
      },
    }
    document.title = productTexts[product].heading.replace(/<[^>]*>?/gm, '')
    return (
      <div className={styles['public-mobile']}>
        <ChatContainer
          flow={flow || 'mobile_onboarding'}
          step="pre_knowledge"
          chatH1={productTexts[product].heading}
          chatH2={productTexts[product].h2}
          chatSideBody={productTexts[product].h2content}
          firstMessage={productTexts[product].firstChatMessage}
          clientKnowledge={{
            mobile: {
              product: productTexts[product].subscriptionValue,
            },
          }}
        />

        {/* <SvgDivider path="M0,22h1080V8.067c0,0-539.279-19.53-1080,2.186V22z" />
        <section className="mobile-information">
          <StepCard></StepCard>
        </section> */}
        {/* <SvgDivider path="M0,22h1080V8.067c0,0-539.279-19.53-1080,2.186V22z" />
        <section className="mobile-information">
          <div className="mobile-includes  mobile hidden">
            <Container textAlign="center">
              <p className="line-back-title">
                <span>{gettext("Our products include")}</span>
              </p>
              <div className="ui stackable doubling five column grid">
                {productIncludesStatements.map((item, index) => (
                  <ProductincludesElements statement={item} key={index} />
                ))}
              </div>
            </Container>
          </div>
          <StepCard style={{ marginBottom: 200 }}></StepCard>
        </section> */}

        <ProductLinks />
        <div className={styles['mobile-faq']}>
          <Container>
            <div className={styles['svg-curve']}>
              <svg
                version="1.1"
                fill="#FFFFF7"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                viewBox="0 0 1080 65"
                preserveAspectRatio="none"
              >
                <path d="M0,45.069C671.161,45.478,1080,0,1080,0v65H0V45.069z" />
              </svg>
            </div>
            <Grid stackable>
              <Grid.Row textAlign="left">
                <Grid.Column width="7">
                  <h3 className={styles['section-title']}>
                    <ReactMarkdown
                      source={xgettext(
                        'All you need to know about <strong>mobile</strong>',
                        'mobile faq header'
                      )}
                      escapeHtml={false}
                    />
                  </h3>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row textAlign="left">
                {faq.map((f) => {
                  return <Faq faq={f} key={f.name} />
                })}
              </Grid.Row>
            </Grid>
          </Container>
        </div>
      </div>
    )
  }
}

export default connect(
  (state) => ({
    login: state.core.auth.login,
    credentials: state.global.credentials,
  }),
  (dispatch) => ({
    actions: Object.assign(
      {},
      { dispatch },
      bindActionCreators({ setCredentials }, dispatch)
    ),
  })
)(MobileProduct)

function Faq(props) {
  return (
    <Grid.Column width="8">
      <a href={props.faq.url}>{props.faq.name}</a>
    </Grid.Column>
  )
}
const ProductincludesElements = (props) => (
  <Grid.Column>
    <p style={{ color: '#ffffff' }}>
      <img src={process.env.PUBLIC_URL + '/assets/icon-check-mark.svg'} />{' '}
      <b>{props.statement}</b>
    </p>
  </Grid.Column>
)
