import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Button, Card, Modal, Header, Icon, Popup } from 'semantic-ui-react';
import { Scrollbars } from 'react-custom-scrollbars';
import moment from 'moment';

import Chat from '../../../../components/Chat';
import store from '../../../../store/configureStore';

import styles from './styles.module.scss';

class InsuranceCard extends React.Component {
  static contextTypes = {
    xgettext: PropTypes.func.isRequired,
    gettext: PropTypes.func.isRequired,
  };

  render() {
    const { subscriptions, flow, flowStep, lastUpdated } = this.props;
    const { gettext } = this.context;
    return (
      <Card fluid className={styles.insuranceCard}>
        <div className={`content ${styles.content}`}>
          <div className={styles.header}>
            {gettext('Forsikring')}{' '}
            {lastUpdated ? (
              <span className={styles.infoIcon}>
                <Popup
                  content={`${gettext('Sist oppdatert:')} ${moment(lastUpdated)
                    .format('MMMM DD YYYY')
                    .capitalize()}`}
                  trigger={<Icon circular name="info" size="mini" />}
                />
              </span>
            ) : null}
          </div>
          <Scrollbars style={{ width: '100%', height: 320 }}>
            <div className={styles.subscriptionsContainer}>
              {subscriptions.map((s) => (
                <div className={styles.subscription} key={s.id}>
                  <div className={styles.insuranceCount}>
                    <span className={styles.insurance}>
                      {s.count} {gettext('Active')}{' '}
                      {s.count > 1 ? gettext('poliser') : gettext('polise')}
                    </span>
                  </div>
                  <div className={styles.detailsContainer}>
                    <div className={styles.productType}>
                      {s.subscriptionName}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </Scrollbars>
          <div className={styles.footer}>
            <Button
              size="big"
              style={{
                color: '#ffffff',
                fontWeight: 600,
                borderRadius: 30,
                background: 'linear-gradient(225deg, #fb637e 0%, #ff6900 100%)',
              }}
              onClick={() =>
                window.open('https://frende.no/minside/', '_blank')
              }
            >
              {gettext('Frende Min Side')}
            </Button>
            <Modal
              trigger={
                <Button
                  size="big"
                  style={{
                    color: '#ffffff',
                    fontWeight: 600,
                    borderRadius: 30,
                    background: '#3f2a56',
                  }}
                >
                  {gettext('Bestill')}
                </Button>
              }
              basic
              size="tiny"
              closeIcon
              // defaultOpen
            >
              <Header />
              <Modal.Content style={{ minHeight: 1500 }}>
                <div style={{ minHeight: 900 }}>
                  <Chat goalFlow={flow} step={flowStep} store={store} />
                </div>
              </Modal.Content>
            </Modal>
          </div>
        </div>
      </Card>
    );
  }
}
export default withRouter(
  connect(
    (state) => ({
      config: state.global.config,
    }),
    (dispatch) => ({
      actions: Object.assign(
        {},
        { dispatch },
        bindActionCreators({}, dispatch),
      ),
    }),
  )(InsuranceCard),
);

InsuranceCard.propTypes = {
  subscriptions: PropTypes.arrayOf(PropTypes.object),
  flow: PropTypes.string.isRequired,
  flowStep: PropTypes.string.isRequired,
  lastUpdated: PropTypes.any,
};
InsuranceCard.defaultProps = {
  subscriptions: [],
  lastUpdated: null,
};
