import React from 'react';
import {Container, Grid} from 'semantic-ui-react';

export default () => (
  <Container style={{paddingTop: 190, paddingBottom: 190}}>
    <Grid stackable style={{justifyContent: 'center'}} verticalAlign="middle">
      <Grid.Row textAlign="left">
        <Grid.Column width="twelve">This path does not exist...</Grid.Column>
      </Grid.Row>
    </Grid>
  </Container>
);
