import React from 'react'
import PropTypes from 'prop-types'
import { Grid, Container } from 'semantic-ui-react'
import ReactMarkdown from 'react-markdown/with-html'

import styles from './chatContainer.module.css'
import Chat from '../../components/Chat'

const ChatContainer = (props, context) => {
  const { gettext, xgettext } = context

  return (
    <header className={styles.header}>
      {/* <a href={campaignUrl} target="_blank">
        <img
          className="mobile-sale-home"
          src={process.env.PUBLIC_URL + campaignImg}
        />
      </a> */}

      <Container>
        <Grid stackable textAlign="left" style={{ paddingBottom: 100 }}>
          <Grid.Row>
            <Grid.Column width="11" style={{ marginTop: 15 }}>
              {props.chatH1 && (
                <h1 style={{ marginTop: -30 }}>
                  <ReactMarkdown source={props.chatH1} escapeHtml={false} />
                </h1>
              )}
              <Chat
                clientKnowledge={props.clientKnowledge}
                goalFlow={props.flow}
                step={props.step}
                firstMessage={props.firstMessage}
              />
            </Grid.Column>
            <Grid.Column width="5">
              <h2 style={{ marginTop: 10, fontStyle: 'normal' }}>
                {props.chatH2}
              </h2>
              <ReactMarkdown source={props.chatSideBody} escapeHtml={false} />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width="10" textAlign="left"></Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    </header>
  )
}
ChatContainer.contextTypes = {
  gettext: PropTypes.func.isRequired,
  xgettext: PropTypes.func.isRequired,
}
export default ChatContainer
