import React from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Grid, Container } from 'semantic-ui-react'
import ReactMarkdown from 'react-markdown/with-html'

import styles from './productLinks.module.css'
import { cmsApi } from '../../../../store/webapi/cms'
class ProductLinks extends React.PureComponent {
  static contextTypes = {
    gettext: PropTypes.func.isRequired,
    xgettext: PropTypes.func.isRequired,
  }

  static propTypes = {
    config: PropTypes.object.isRequired,
  }

  constructor(props, context) {
    super(props, context)
  }
  componentDidMount() {
    // this.props.actions.dispatch(cmsApi.actions.faq.sync({}))
  }
  render() {
    const { gettext, xgettext } = this.context

    const { defaultLocale, activeProducts, urlLocaleMap } = this.props.config
    return (
      <div className={styles['faq']}>
        <Container>
          <div className={styles['svg-curve']}>
            <svg
              version="1.1"
              fill="#FFFFF7"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              viewBox="0 0 1080 65"
              preserveAspectRatio="none"
            >
              <path d="M0,45.069C671.161,45.478,1080,0,1080,0v65H0V45.069z" />
            </svg>
          </div>
          <Grid stackable>
            <Grid.Row textAlign="left">
              <Grid.Column width="8">
                <h3 className={styles['section-title']}>
                  <ReactMarkdown
                    source={xgettext(
                      'Everyday services <strong>in one place</strong>',
                      'login faq header'
                    )}
                    escapeHtml={false}
                  />
                </h3>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row textAlign="left">
              <Grid.Column width="8">
                <h2>{gettext('Insurance')}</h2>

                <a
                  href="/forsikring"
                  className={
                    activeProducts.insurance[defaultLocale]
                      ? ''
                      : styles['disabled-link']
                  }
                >
                  {xgettext('View Insurance product', 'login mobile link text')}
                </a>
              </Grid.Column>
              <Grid.Column width="8">
                <h2>{gettext('Refinancing')}</h2>

                <a
                  href="/refinansiering"
                  className={
                    activeProducts.refinancing[defaultLocale]
                      ? ''
                      : styles['disabled-link']
                  }
                >
                  {xgettext(
                    'View refinancing product',
                    'login refiancing link text'
                  )}
                </a>
              </Grid.Column>
              <Grid.Column width="8">
                <h2>{gettext('Mobile')}</h2>
                <a
                  href="/mobilabonnement"
                  className={
                    activeProducts.mobile[defaultLocale]
                      ? ''
                      : styles['disabled-link']
                  }
                >
                  {xgettext('View Mobile product', 'login mobil link text')}
                </a>
              </Grid.Column>
              {/* <Grid.Column width="8">
                <h2>{gettext('Power')}</h2>

                <a
                  href={urlLocaleMap.power[defaultLocale]}
                  className={
                    activeProducts.power[defaultLocale]
                      ? ''
                      : styles['disabled-link']
                  }
                >
                  {xgettext('View Power product', 'login power link text')}
                </a>
              </Grid.Column> */}
            </Grid.Row>
          </Grid>
        </Container>
      </div>
    )
  }
}

export default connect(
  (state) => ({
    config: state.global.config,
  }),
  (dispatch) => ({
    actions: Object.assign({}, { dispatch }, bindActionCreators({}, dispatch)),
  })
)(ProductLinks)
