import React from 'react'
import PropTypes from 'prop-types'
import jwtDecode from 'jwt-decode'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Card, Container, Grid, Icon } from 'semantic-ui-react'
import { Redirect } from 'react-router-dom'

import ChatContainer from '../../../components/ChatContainer'
import ProductSaleCard from './components/ProductSaleCard'
import SvgDivider from '../../../components/SvgDivider'
import styles from './home.module.css'
import { legalEntityAPI } from '../../../store/webapi/legalEntity'
import { setCredentials } from '../../../store/global/credentials'

class Home extends React.PureComponent {
  static contextTypes = {
    xgettext: PropTypes.func.isRequired,
    gettext: PropTypes.func.isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }),
  }

  logUserIn(jwt) {
    if (!jwt) {
      return
    }
    this.props.actions.setCredentials(jwt)

    const decodedJWT = jwtDecode(jwt)

    this.props.actions.dispatch(
      //accountAPI.actions.account.sync({ id: decodedJWT.sub })
      legalEntityAPI.actions.entity.sync({ id: decodedJWT.sub })
    )
  }
  render() {
    const { gettext, xgettext } = this.context
    const jwt = localStorage.getItem('brainSessionJwt')

    const { defaultLocale, activeProducts, urlLocaleMap } = this.props.config
    const location = document.location.pathname
    const step = 'init_with_fixed_start'
    const chatH1 = xgettext(
      'Welcome to <strong> My Hudya</strong>',
      'login main header'
    )
    const chatH2 = gettext('Life, simplified')
    const chatSideBody = gettext(
      'Du trenger ikke lenger å inngå avtaler med mange ulike leverandører for å håndtere hverdagen.</br><strong>Her får du tjenestene du trenger på ett sted!</strong>'
    )

    let flow = ''
    // let campaignImg = ''
    // let campaignUrl = ''
    if (defaultLocale === 'sv_SE') {
      flow = location === '/login' ? 'generic_signup' : 'se_landing_page'
      // campaignImg = '/media/sverige.png'
      // campaignUrl =
      //   'https://www.nordnet.se/se/kampanjer/ipo/hudya-se/nordnets-emissionstjanster'
    }
    if (defaultLocale === 'nb_NO') {
      flow = location === '/login' ? 'generic_signup' : 'mobile_from_external'
      // campaignImg = '/media/norge.png'
      // campaignUrl = 'https://www.nordnet.no/no/kampanjer/hudya/hudya-disclaimer'
    }
    if (jwt && !this.props.credentials.isAuthenticated) {
      this.logUserIn(jwt)
    }
    if (this.props.credentials.isAuthenticated && location == '/login') {
      return (
        <Redirect
          to={{
            pathname: '/welcome',
            state: { from: '/' },
          }}
        />
      )
    }
    return (
      <div className={styles.overview}>
        <ChatContainer
          flow={flow}
          step={step}
          chatH2={chatH2}
          chatSideBody={chatSideBody}
        />
        <SvgDivider path="M0,22h1080V8.067c0,0-539.279-19.53-1080,2.186V22z" />
        <div className={styles.productSection}>
          <Container>
            <div className="ui stackable doubling four column grid center aligned">
              <Grid.Column>
                <ProductSaleCard
                  header={gettext('Mobil')}
                  desc={gettext(
                    'Full Telenor-dekning, Data Rollover, gratis BankID og ingen bindingstid'
                  )}
                  disabled={!activeProducts.mobile[defaultLocale]}
                  flow="mobile_onboarding"
                  price={gettext('mobile price')}
                  priceTopText={gettext('Fra kr')}
                  priceBottomText={gettext('per month')}
                  readMore={gettext('Alle abonement')}
                  readMoreUri="/mobilabonnement/produkter"
                  orderButtonText={gettext('Bestill nå')}
                  orderButtonUri="/mobilabonnement"
                />
              </Grid.Column>
              {/* <Grid.Column>
                <ProductSaleCard
                  header={gettext('Strøm')}
                  desc={gettext('Ingen fastavgift eller bindingstid')}
                  disabled={!activeProducts.power[defaultLocale]}
                  flow="power_onboarding"
                  price={gettext('Power price')}
                  priceTopText={gettext('Fast påslag')}
                  readMore={gettext('Les mer')}
                  readMoreUri={urlLocaleMap.power[defaultLocale]}
                  priceBottomText={gettext('øre/KWh')}
                  orderButtonText={gettext('Bestill nå')}
                  orderButtonUri={urlLocaleMap.power[defaultLocale]}
                />
              </Grid.Column> */}
              <Grid.Column>
                <ProductSaleCard
                  header={gettext('Forsikring')}
                  desc={gettext(
                    'Vår bil-, barne- og innboforsikring er kåret til best i test'
                  )}
                  disabled={!activeProducts.insurance[defaultLocale]}
                  flow="insurance_leads"
                  price={<Icon name="handshake outline" />}
                  priceTopText={gettext('Vi er her')}
                  orderButtonText={gettext('Få tilbud')}
                  readMore={gettext('Les mer')}
                  readMoreUri="/forsikring"
                  priceBottomText={gettext('for deg')}
                  orderButtonUri="/forsikring"
                />
              </Grid.Column>
              <Grid.Column>
                <ProductSaleCard
                  header={gettext('Refinansiering')}
                  desc={gettext('Refinansiering i samarbeid med 18 banker')}
                  disabled={!activeProducts.refinancing[defaultLocale]}
                  flow="aconto_refinancing"
                  price={gettext('52 000')}
                  priceTopText={gettext('Våre kunder sparer')}
                  readMore={gettext('Les mer')}
                  readMoreUri="/refinansiering"
                  priceBottomText={gettext('i gjennomsnitt')}
                  orderButtonText={gettext('Søk nå')}
                  orderButtonUri="/refinansiering"
                />
              </Grid.Column>
            </div>
          </Container>
        </div>
        <div className={styles.information}>
          <Container>
            <Card fluid className="help-card big-help">
              <div className="content">
                <Grid stackable doubling>
                  <div className="ten wide tablet seven wide computer column">
                    <h4 className="help-title">
                      {gettext('Do you')}{' '}
                      <strong>{gettext('need help?')}</strong>
                    </h4>
                    <p>
                      {gettext(
                        'Vår kundesupport er åpen hverdager fra 9-16 og står klare til å bistå deg! ring oss på'
                      )}
                      <a
                        type="tel"
                        href={'tel:' + gettext('004721415600')}
                        style={{
                          color: '#3f2a56',
                          fontSize: 22,
                          marginLeft: 5,
                          fontWeight: 'bold',
                        }}
                      >
                        {gettext('21 41 56 00')}
                      </a>
                    </p>
                  </div>
                  <div className="six wide tablet eight wide computer column right aligned">
                    <img
                      alt="wooden table with a remote and a coffe cup"
                      src={
                        process.env.PUBLIC_URL + '/media/section-help-table.png'
                      }
                    />
                  </div>
                </Grid>
              </div>
            </Card>
          </Container>
        </div>
      </div>
    )
  }
}

export default connect(
  (state) => ({
    login: state.core.auth.login,
    credentials: state.global.credentials,
    chat: state.global.bob.chatElements,
    config: state.global.config,
  }),
  (dispatch) => ({
    actions: Object.assign(
      {},
      { dispatch },
      bindActionCreators({ setCredentials }, dispatch)
    ),
  })
)(Home)
