import React from 'react'
import PropTypes from 'prop-types'
import { Modal, Header, Icon, Tab } from 'semantic-ui-react'
import TopSaleSection from './components/TopSaleSection'
import panesNoFlex from './components/DataTables/noFlex'

const MoreInfo = (props, context) => {
  const { gettext } = context
  return (
    <Modal
      trigger={
        <a className="link-more-info" href="#MoreInfo">
          <Icon name="info circle" />
          {gettext('More info')}
        </a>
      }
      size="large"
      closeIcon
    >
      <Header icon="info circle" content={props.name} color="violet" />
      <Modal.Content>{TopSaleSection[props.name]} </Modal.Content>
      <Modal.Content style={{ minHeight: 1500 }}>
        <Tab
          style={{ minHeight: 900 }}
          menu={{
            attached: false,
            tabular: false,
            stackable: true
          }}
          panes={panesNoFlex}
        />
      </Modal.Content>
    </Modal>
  )
  // return (
  //   <span>
  //     <Icon name="info circle" className="link-more-info" />
  //     <a href={props.link} className="link-more-info">
  //       {gettext('More info')}
  //     </a>
  //   </span>
  // )
}

MoreInfo.contextTypes = {
  gettext: PropTypes.func.isRequired
}

export default MoreInfo
