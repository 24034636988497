import React from 'react'
import { CSSTransition } from 'react-transition-group'
import PropTypes from 'prop-types'
import { Icon, Input, Button } from 'semantic-ui-react'

const InputElements = (props, context) => (
  <CSSTransition
    appear
    in={props.visible}
    className="chat-input-group"
    classNames="chat-input"
    timeout={{
      enter: 2000,
      exit: 2000,
    }}
    unmountOnExit
  >
    {props.inputType === 'text' && (
      <div hidden={!props.visible}>
        {props.suggestedResponses.map((button, index) => (
          <Button
            icon={button.icon && button.icon.override ? true : false}
            style={{ fontSize: 16, marginBottom: 5 }}
            circular
            secondary
            className="small"
            key={index}
            onClick={props.handleButtonAlternative}
            payload={button.payload}
            type="button"
            utterance={button.title}
          >
            {button.icon && <Icon name={button.icon.name} />}
            {button.icon && !button.icon.override && button.title}
            {!button.icon && button.title}
          </Button>
        ))}

        {props.inputType === 'text' &&
          !props.hideTextInput &&
          props.resultingState === 'AWAIT_NARRATIVE' && (
            <div className="chat-input">
              <InputField
                inputType={props.parameters.input_type}
                submitButtonText={
                  props.parameters.input_button_text || context.gettext('Send')
                }
                inputMode={props.parameters.input_mode}
                fuzzyValue={props.fuzzyValue}
                handleFieldChange={props.handleFieldChange}
                pattern={props.parameters.input_pattern}
                inputFocusToggle={props.inputFocusToggle}
              />
            </div>
          )}
      </div>
    )}
  </CSSTransition>
)

InputElements.propTypes = {
  visible: PropTypes.bool.isRequired,
  inputType: PropTypes.string.isRequired,
  parameters: PropTypes.object,
  handleButtonAlternative: PropTypes.func.isRequired,
  handleFieldChange: PropTypes.func.isRequired,
}
InputElements.defaultProps = {
  inputType: 'text',
  userResponse: false,
  submitButtonText: 'Send',
  parameters: {},
}
InputElements.contextTypes = {
  gettext: PropTypes.func.isRequired,
}
export default InputElements

class InputField extends React.Component {
  constructor(props) {
    super(props)
    this.inputRef = React.createRef()
  }
  componentDidMount() {
    if (window.innerWidth >= 800) {
      this.inputRef.current.focus()
    }
  }

  render() {
    let a = 1
    return (
      <Input
        label={<Button type="submit">{this.props.submitButtonText}</Button>}
        type={this.props.inputType}
        pattern={this.props.pattern}
        size="large"
        inputMode={this.props.inputMode}
        value={this.props.fuzzyValue}
        onChange={this.props.handleFieldChange}
        ref={this.inputRef}
        onFocus={this.props.inputFocusToggle}
        onBlur={this.props.inputFocusToggle}
      />
    )
  }
}
// const InputField = props => (
//   //   <div className="ui medium labeled input">
//   //     <button type="submit" className="ui button label" role="button">
//   //       {props.submitButtonText}
//   //     </button>
//   //     <input
//   //       type={props.inputType}
//   //       inputmode={props.inputMode}
//   //       pattern={props.pattern}
//   //       onChange={props.handleFieldChange}
//   //       value={props.fuzzyValue}
//   //     />
//   //   </div>
//   <Input
//     label={<Button type="submit">{props.submitButtonText}</Button>}
//     type={props.inputType}
//     pattern={props.pattern}
//     size="medium"
//     inputMode={props.inputMode}
//     value={props.fuzzyValue}
//     onChange={props.handleFieldChange}
//   />
// )
InputField.propTypes = {
  inputType: PropTypes.string.isRequired,
  submitButtonText: PropTypes.string,
}
InputField.defaultProps = {
  inputType: 'text',
  inputMode: 'text',
  submitButtonText: 'Send',
  pattern: '*',
  suggestedResponses: [],
  parameters: {},
}
