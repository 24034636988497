/**
 * Renders a single row on a product card.
 */

import React from 'react'
import PropTypes from 'prop-types'
import { Grid } from 'semantic-ui-react'

const METERINGPOINT_ID_LENGTH = 6

export default class PowerProductCardItem extends React.PureComponent {
  static contextTypes = { gettext: PropTypes.func.isRequired }
  static propTypes = {
    addressText: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
      .isRequired,
    meteringPointId: PropTypes.string.isRequired,
    meteringPointStatus: PropTypes.object.isRequired
  }

  constructor(props, context) {
    super(props, context)
    this.style = {
      label: {
        display: 'block',
        fontSize: 'small',
        fontWeight: 'bold'
      },
      text: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
      }
    }
  }

  render() {
    const { gettext } = this.context
    const { addressText, meteringPointId, meteringPointStatus } = this.props

    return (
      <Grid stackable columns="equal">
        <Grid.Row>
          <Grid.Column>
            <div>
              <label style={this.style.label}>{gettext('Address')}</label>
              <span>{addressText}</span>
            </div>
          </Grid.Column>
          <Grid.Column>
            <div style={this.style.text}>
              <label style={this.style.label}>
                {gettext('Metering point')}
              </label>
              <span>
                {meteringPointId.length >= METERINGPOINT_ID_LENGTH
                  ? meteringPointId
                  : 'No metering point id'}
              </span>
            </div>
          </Grid.Column>
          <Grid.Column>
            <div>
              <label style={this.style.label}>{gettext('Status')}</label>
              <span>{meteringPointStatus}</span>
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    )
  }
}
