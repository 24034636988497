import PropTypes from 'prop-types'
import React from 'react'
import withGettext from 'react-gettext'
import { connect, Provider } from 'react-redux'
import { bindActionCreators, compose } from 'redux'
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'

import { resetCredentials } from '../store/global/credentials'
import {
  togglePushMenu,
  toggleFullscreen,
} from '../store/global/gui/navigation'
import { toggleContentfulPreview } from '../store/global/contentful/config'
import { i18Trans } from '../store/global/contentful/translations'
import Consent from '../scenes/Consent'
import ScrollToTop from '../utils/ScrollToTop'
import 'react-toastify/dist/ReactToastify.css'

import { WPProvider } from '../services/wpService/wpService'

import NoMatch from './NoMatch'
import GlobalMessage from './GlobalMessage'
import TopMenu from './Layout/TopMenu'
import PushMenuWrappwer from './Layout/PushMenuWrapper'
import Splash from './Splash'
import { GlobalHistory } from './History'

// import { Message } from 'semantic-ui-react';
// import GhostContentAPI from '@tryghost/content-api'

// toast(
//   'Aconto.no AS har overtatt virksomheten til Hudya.no. Alle tjenestene videreføres uforandret men under navnet Aconto.no AS.',
//   { type: 'info' },
// );

class AppProvider extends React.Component {
  static propTypes = {
    actions: PropTypes.shape({
      i18Trans: PropTypes.func.isRequired,
      resetCredentials: PropTypes.func.isRequired,
      toggleContentfulPreview: PropTypes.func.isRequired,
      togglePushMenu: PropTypes.func.isRequired,
    }).isRequired,
    // contentful: PropTypes.object.isRequired,
    credentials: PropTypes.object.isRequired,
    navigation: PropTypes.object.isRequired,
    routes: PropTypes.array.isRequired,
    runtimeEnv: PropTypes.string.isRequired,
    store: PropTypes.object.isRequired,
  }

  shouldComponentUpdate(nextProps) {
    const { expires } = nextProps.credentials

    if (expires && new Date(Date.now()) >= new Date(expires * 1000)) {
      resetCredentials()
      const logout = () => ({ type: 'LOGOUT' })

      this.props.actions.dispatch(logout())
    }

    return true
  }

  componentDidUpdate(prevProps) {}

  //
  render() {
    // const api = new GhostContentAPI({
    //   url: "https://nakkvekierulf.ghost.io",
    //   key: "8bb8d4130dd3437bff1aaa1001",
    //   version: "v3"
    // });

    // // fetch 5 posts, including related tags and authors
    // api.posts
    //   .browse({ include: "tags,authors" })
    //   .then(posts => {
    //     posts.forEach(post => {
    //       console.log(post.title);
    //     });
    //   })
    //   .catch(err => {
    //     console.error(err);
    //   });
    // api.pages.browse();
    const { credentials, routes, runtimeEnv, store } = this.props

    const SubRoute = (route) => (
      <Route
        path={route.path}
        render={(props) => <route.component {...props} routes={route.routes} />}
      />
    )

    const ProtectedRoute = ({ component: Component, ...rest }) => (
      <Route
        {...rest}
        render={(props) =>
          credentials.isAuthenticated ? (
            this.props.credentials.generalConsent ||
            this.props.credentials.telipolConsent ? (
              <Component {...props} />
            ) : window.location.pathname === '/mobile-a' ? (
              <Component {...props} />
            ) : (
              <Consent />
            )
          ) : (
            <Redirect
              to={{ pathname: '/login', state: { from: props.location } }}
            />
          )
        }
      />
    )
    return (
      <div>
        <Provider store={store}>
          <WPProvider>
            <Router>
              <span>
                <ToastContainer
                  position="bottom-right"
                  autoClose={16000}
                  hideProgressBar={false}
                  newestOnTop={false}
                  closeOnClick
                  rtl={false}
                  pauseOnFocusLoss
                  draggable={false}
                  pauseOnHover
                />
                <GlobalHistory />
                <ScrollToTop />
                <TopMenu
                  config={this.props.config}
                  i18Trans={this.props.actions.i18Trans}
                  isToggled={this.props.navigation.pushMenu.isToggled}
                  previewMode={false}
                  runtimeEnv={runtimeEnv}
                  store={store}
                  toggleContentfulPreview={
                    this.props.actions.toggleContentfulPreview
                  }
                  toggleFunc={this.props.actions.togglePushMenu}
                  credentials={this.props.credentials}
                />
                <GlobalMessage messages={[]} />

                <Splash />

                <PushMenuWrappwer routes={this.props.routes}>
                  <Switch>
                    {/* Redirects to force old urls */}
                    <Redirect
                      exact
                      from="/nb/m/"
                      to={{ pathname: '/', state: { from: '/' } }}
                    />
                    <Redirect
                      exact
                      from="/nb/m/:path"
                      to={{ pathname: '/:path', state: { from: '/' } }}
                    />
                    <Redirect
                      exact
                      from="/nb/m/:path/:path2"
                      to={{ pathname: '/:path/:path2/', state: { from: '/' } }}
                    />
                    <Redirect
                      exact
                      from="/mobil"
                      to={{
                        pathname: '/mobilabonnement',
                        state: { from: '/' },
                      }}
                    />
                    <Redirect
                      exact
                      from="/aconto"
                      to={{ pathname: '/refinansiering', state: { from: '/' } }}
                    />
                    <Redirect
                      exact
                      from="/power"
                      to={{ pathname: '/strom', state: { from: '/' } }}
                    />

                    {routes.map((route, index) =>
                      route.protected ? (
                        <ProtectedRoute key={index} {...route} />
                      ) : (
                        <SubRoute key={index} {...route} />
                      )
                    )}

                    <Redirect
                      exact
                      from="/"
                      to={{ pathname: '/products', state: { from: '/' } }}
                    />

                    <Route component={NoMatch} />
                  </Switch>
                </PushMenuWrappwer>
              </span>
            </Router>
          </WPProvider>
        </Provider>
      </div>
    )
  }
}

export default compose(
  connect(
    (state) => ({
      config: state.global.config,
      credentials: state.global.credentials,
      navigation: state.global.gui.navigation,
      runtimeEnv: state.global.runtimeEnv,
    }),
    (dispatch) => ({
      actions: bindActionCreators(
        {
          i18Trans,
          resetCredentials,
          toggleContentfulPreview,
          togglePushMenu,
          toggleFullscreen,
        },
        dispatch
      ),
    })
  ),
  withGettext()
)(AppProvider)
