import React from 'react'
import PropTypes from 'prop-types'

import store from '../../../../../store/configureStore'

import { Grid, Modal, Card, Button, Header, Icon } from 'semantic-ui-react'
import Chat from '../../../../../components/Chat/'
import MoreInfo from '../MoreInfo'
const SubscriptionCard = (props, context) => {
  const { gettext, xgettext } = context
  return (
    <Card fluid className="plan-card">
      <span
        hidden={!props.isFlex}
        style={{
          backgroundColor: '#FF6900',
          fontWeight: 'bold',
          color: '#ffffff'
        }}
      >
        {gettext('Flex pay as you go!')}
      </span>
      <span
        hidden={!props.isChild}
        style={{
          opacity: 1,
          backgroundColor: '#FF6900',
          fontWeight: 'bold',
          color: '#ffffff'
        }}
      >
        {gettext('For de under 13 år')}
      </span>
      <span
        hidden={props.isFlex || props.isChild}
        style={{
          opacity: 0,
          backgroundColor: '#FF6900',
          fontWeight: 'bold',
          color: '#ffffff'
        }}
      >
        {gettext('For de under 13 år')}
      </span>
      <div className="content">
        <h2 className="plan-card-title">{props.name}</h2>
        <Grid>
          <Grid.Row>
            <Grid.Column width="8">
              <p className="plan-card-info">
                <strong>{props.callTimeIncluded}</strong>
                <br />
                MIN/SMS/MMS
              </p>
            </Grid.Column>
            <Grid.Column width="8">
              <p className="plan-card-info">
                <strong>{props.mbInclided}</strong>
                <br />
                {props.dataSize}
              </p>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <p className="plan-card-price">{props.price}</p>
        <p className="plan-card-price-detail">
          <small>{gettext('per month')}</small>
        </p>

        <Modal
          trigger={<Button size="big">{gettext('Order now')}</Button>}
          basic
          size="tiny"
          closeIcon
          defaultOpen={props.defaultOpen}
          // defaultOpen
        >
          <Header icon="chat" content="Snakk med oss!" />
          <Modal.Content style={{ minHeight: 1500 }}>
            <p>
              Her kan du lære mer om våre produkter og bestille våre tjenester
            </p>
            <div style={{ minHeight: 900 }}>
              <Chat goalFlow={props.goalFlow} step={props.step} store={store} />
            </div>
          </Modal.Content>
        </Modal>
        <div>
          <MoreInfo name={props.name} link={props.link} />
        </div>
      </div>
    </Card>
  )
}

SubscriptionCard.contextTypes = {
  gettext: PropTypes.func.isRequired,
  xgettext: PropTypes.func.isRequired
}

export default SubscriptionCard
