import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Button, Card, Modal, Header, Popup, Icon } from 'semantic-ui-react';
import { Scrollbars } from 'react-custom-scrollbars';
import moment from 'moment';

import Chat from '../../../../components/Chat';
import store from '../../../../store/configureStore';
import { formatBytes, formatBytesMode } from '../../../../utils/formatter';

import styles from './styles.module.scss';

class MobileCard extends React.Component {
  static contextTypes = {
    xgettext: PropTypes.func.isRequired,
    gettext: PropTypes.func.isRequired,
  };

  convertToPhoneNumber = (phone) => {
    return `${phone.substr(0, 3)} ${phone.substr(3, 2)} ${phone.substr(5, 3)}`;
  };

  getPercent = (used, total) => {
    return ((used * 100) / total).toFixed(0);
  };

  render() {
    const { gettext } = this.context;
    const { subscriptions, flow, flowStep, history, lastUpdated } = this.props;
    return (
      <Card fluid className={styles.mobileCard}>
        <div className={`content ${styles.content}`}>
          <div className={styles.header}>
            {gettext('Mobil')}
            {lastUpdated ? (
              <span className={styles.infoIcon}>
                <Popup
                  content={`${gettext('Sist oppdatert:')} ${moment(lastUpdated)
                    .format('MMMM DD YYYY')
                    .capitalize()}`}
                  trigger={<Icon circular name="info" size="mini" />}
                />
              </span>
            ) : null}
          </div>
          <Scrollbars style={{ width: '100%', height: 320 }}>
            <div className={styles.subscriptionsContainer}>
              {subscriptions.map((s) => (
                <div className={styles.subscription} key={s.id}>
                  <div
                    className={`${styles.owner} ${styles.trim}`}
                    title={s.subscriptionName}
                  >
                    {s.subscriptionName}
                  </div>
                  <div className={styles.detailsContainer}>
                    <div className={styles.phoneNumber}>
                      {this.convertToPhoneNumber(s.phoneNumber)}
                    </div>
                    {s.failSync ? null : (
                      <div className={styles.transfer}>
                        <span className={styles.transferUsed}>
                          {formatBytes(s.transferUsed * 1000000, 2, null, true)}
                        </span>
                        <span className={styles.transferAv}>
                          av{' '}
                          {formatBytes(
                            s.transferTotal * 1000000,
                            2,
                            null,
                            true,
                          )}
                        </span>
                      </div>
                    )}
                  </div>
                  <div className={styles.progress}>
                    <div
                      className="ui white progress"
                      style={{
                        borderRadius: 4,
                        background: 'rgba(255,105,0,0.5)',
                        margin: 0,
                      }}
                      data-percent={this.getPercent(
                        s.failSync ? 0 : s.transferUsed,
                        s.failSync ? 0 : s.transferTotal,
                      )}
                    >
                      <div
                        className="bar"
                        style={{
                          width: `${this.getPercent(
                            s.failSync ? 0 : s.transferUsed,
                            s.failSync ? 0 : s.transferTotal,
                          )}%`,
                          borderRadius: 4,
                          background: 'rgba(255,105,0,1)',
                          minWidth: 0,
                        }}
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </Scrollbars>
          <div className={styles.footer}>
            <Button
              size="big"
              style={{
                color: '#ffffff',
                fontWeight: 600,
                borderRadius: 30,
                background: 'linear-gradient(225deg, #fb637e 0%, #ff6900 100%)',
              }}
              onClick={() => history.push('/products/details/mobile')}
            >
              {gettext('Se dine abonnement')}
            </Button>
            <Modal
              trigger={
                <Button
                  size="big"
                  style={{
                    color: '#ffffff',
                    fontWeight: 600,
                    borderRadius: 30,
                    background: '#3f2a56',
                  }}
                >
                  {gettext('Bestill')}
                </Button>
              }
              basic
              size="tiny"
              closeIcon
              // defaultOpen
            >
              <Header />
              <Modal.Content style={{ minHeight: 1500 }}>
                <div style={{ minHeight: 900 }}>
                  <Chat goalFlow={flow} step={flowStep} store={store} />
                </div>
              </Modal.Content>
            </Modal>
          </div>
        </div>
      </Card>
    );
  }
}
export default withRouter(
  connect(
    (state) => ({
      config: state.global.config,
    }),
    (dispatch) => ({
      actions: Object.assign(
        {},
        { dispatch },
        bindActionCreators({}, dispatch),
      ),
    }),
  )(MobileCard),
);

MobileCard.propTypes = {
  subscriptions: PropTypes.arrayOf(PropTypes.object),
  flow: PropTypes.string.isRequired,
  flowStep: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
  lastUpdated: PropTypes.any,
};
MobileCard.defaultProps = {
  subscriptions: [],
  lastUpdated: null,
};
