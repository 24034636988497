import React from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Container, Grid } from 'semantic-ui-react'
import { Redirect } from 'react-router-dom'
import styles from './insurance.module.css'
import ReactMarkdown from 'react-markdown/with-html'
import faq from './faq'

import Chat from '../../../components/Chat'
import ProductLinks from '../components/ProductLinks'
import ChatContainer from '../../../components/ChatContainer'

class PublicInsurance extends React.PureComponent {
  static contextTypes = {
    gettext: PropTypes.func.isRequired,
    xgettext: PropTypes.func.isRequired,
  }

  static propTypes = {}

  constructor(props, context) {
    super(props, context)
    document.title = context.xgettext(
      'Forsikring - Hudya',
      'Insurance page title'
    )
  }

  render() {
    const params = new URLSearchParams(window.location.search)
    const { gettext, xgettext } = this.context

    const flow = params.get('flow')

    // if (this.props.credentials.isAuthenticated) {
    //   return (
    //     <Redirect
    //       to={{
    //         pathname: '/welcome',
    //         state: { from: '/' }
    //       }}
    //     />
    //   )
    // }
    return (
      <div className={styles['insurance-mobile']}>
        <ChatContainer
          flow={flow || 'insurance_mobile_discount'}
          step="init"
          chatH1={xgettext('Hudya Insurance', 'insurance landing page')}
          chatH2={gettext('Insurance sales heading')}
          chatSideBody={gettext('Insurance sales text')}
          firstMessage={xgettext(
            'Vi har et solid skadeoppgjør og landets mest fornøyde og lojale kunder',
            'insurance_leads'
          )}
        />

        <ProductLinks />
        <div className={styles['insurance-faq']}>
          <Container>
            <div className={styles['svg-curve']}>
              <svg
                version="1.1"
                fill="#FFFFF7"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                viewBox="0 0 1080 65"
                preserveAspectRatio="none"
              >
                <path d="M0,45.069C671.161,45.478,1080,0,1080,0v65H0V45.069z" />
              </svg>
            </div>
            <Grid stackable>
              <Grid.Row textAlign="left">
                <Grid.Column width="7">
                  <h3 className={styles['section-title']}>
                    <ReactMarkdown
                      source={xgettext(
                        'Our <strong>most popular</strong> insurances',
                        'mobile faq header'
                      )}
                      escapeHtml={false}
                    />
                  </h3>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row textAlign="left">
                {faq.map((f) => {
                  return <Faq faq={f} />
                })}
              </Grid.Row>
            </Grid>
          </Container>
        </div>
      </div>
    )
  }
}

export default connect(
  (state) => ({
    login: state.core.auth.login,
    credentials: state.global.credentials,
  }),
  (dispatch) => ({
    actions: bindActionCreators({}, dispatch),
  })
)(PublicInsurance)

function Faq(props) {
  return (
    <Grid.Column width="8">
      <a href={props.faq.url}>{props.faq.name}</a>
    </Grid.Column>
  )
}
