import React from 'react';
import PropTypes from 'prop-types';
import { Card, Container, Grid, Icon } from 'semantic-ui-react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import renderHTML from 'react-render-html';

import { authAPI } from '../../store/webapi/auth';
import { resetBob } from '../../store/global/bob';

import ProductCard from './components/ProductCard';
import styles from './overview.module.css';
import MobileCard from './components/MobileCard/MobileCard';
import PowerCard from './components/PowerCard/PowerCard';
import InsuranceCard from './components/InsuranceCard/InsuranceCard';

class Overview extends React.Component {
  static contextTypes = {
    xgettext: PropTypes.func.isRequired,
    gettext: PropTypes.func.isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }),
  };

  static propTypes = {
    config: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
    providers: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      bobEvent: 'hudya_intro',
    };

    props.actions.resetBob();
  }

  render() {
    const { gettext } = this.context;
    const { config, providers } = this.props;
    const { defaultLocale, activeProducts, urlLocaleMap } = config;
    let powerGoalflow = '';
    if (defaultLocale === 'sv_SE') {
      powerGoalflow = 'se_power_onboarding';
    }
    if (defaultLocale === 'nb_NO') {
      powerGoalflow = 'power_onboarding';
    }
    return (
      <div className={styles.overview}>
        <div className={styles.productSection}>
          <Container>
            <div className="ui stackable doubling four column grid center aligned">
              <Grid.Column>
                {providers.eRate.sync &&
                providers.eRate.subscriptions.length > 0 ? (
                  <MobileCard
                    subscriptions={providers.eRate.subscriptions || []}
                    lastUpdated={providers.eRate.lastUpdated || null}
                    flow="mobile_onboarding"
                    flowStep="init_with_fixed_start"
                  />
                ) : (
                  <ProductCard
                    header={gettext('Mobil')}
                    desc={gettext(
                      'Full Telenor-dekning, Data Rollover, gratis BankID og ingen bindingstid',
                    )}
                    disabled={!activeProducts.mobile[defaultLocale]}
                    flow="mobile_onboarding"
                    flowStep="init_with_fixed_start"
                    price={gettext('mobile price')}
                    priceTopText={gettext('Fra kr')}
                    priceBottomText={gettext('per month')}
                    readMore={gettext('Alle abonement')}
                    readMoreUri="/mobil/produkter"
                    orderButtonText={gettext('Bestill nå')}
                    loading={!providers.eRate.sync}
                  />
                )}
              </Grid.Column>
              {/*<Grid.Column>*/}
              {/*  {providers.esn.sync &&*/}
              {/*  providers.esn.subscriptions.length > 0 ? (*/}
              {/*    <PowerCard*/}
              {/*      subscriptions={providers.esn.subscriptions || []}*/}
              {/*      lastUpdated={providers.esn.lastUpdated || null}*/}
              {/*      flow={powerGoalflow}*/}
              {/*      flowStep="init_with_fixed_start"*/}
              {/*    />*/}
              {/*  ) : (*/}
              {/*    <ProductCard*/}
              {/*      header={gettext('Strøm')}*/}
              {/*      desc={gettext('Ingen fastavgift eller bindingstid')}*/}
              {/*      disabled={!activeProducts.power[defaultLocale]}*/}
              {/*      flow={powerGoalflow}*/}
              {/*      flowStep="init_with_fixed_start"*/}
              {/*      price={gettext('Power price')}*/}
              {/*      priceTopText={gettext('Fast påslag')}*/}
              {/*      readMore={gettext('Les mer')}*/}
              {/*      readMoreUri={urlLocaleMap.power[defaultLocale]}*/}
              {/*      priceBottomText={gettext('øre/KWh')}*/}
              {/*      orderButtonText={gettext('Bestill nå')}*/}
              {/*      loading={!providers.esn.sync}*/}
              {/*    />*/}
              {/*  )}*/}
              {/*</Grid.Column>*/}
              <Grid.Column>
                {providers.frende.sync &&
                providers.frende.subscriptions.length > 0 ? (
                  <InsuranceCard
                    subscriptions={providers.frende.subscriptions || []}
                    lastUpdated={providers.frende.lastUpdated || null}
                    flow="insurance_mobile_discount"
                    flowStep="init"
                  />
                ) : (
                  <ProductCard
                    header={gettext('Forsikring')}
                    desc={gettext(
                      'Vår bil-, barne- og innboforsikring er kåret til best i test',
                    )}
                    disabled={!activeProducts.insurance[defaultLocale]}
                    flow="insurance_mobile_discount"
                    flowStep="init"
                    price={<Icon name="handshake outline" />}
                    priceTopText={gettext('Vi er her')}
                    orderButtonText={gettext('Få tilbud')}
                    readMore={gettext('Les mer')}
                    readMoreUri="/forsikring"
                    priceBottomText={gettext('for deg')}
                    loading={!providers.frende.sync}
                    // loginUri="https://frende.no/minside/"
                  />
                )}
              </Grid.Column>
              <Grid.Column>
                <ProductCard
                  header={gettext('Refinansiering')}
                  desc={gettext('Refinansiering i samarbeid med 18 banker')}
                  disabled={!activeProducts.refinancing[defaultLocale]}
                  flow="debt_check"
                  flowStep="skip_start"
                  price={gettext('52 000')}
                  priceTopText={gettext('Våre kunder sparer')}
                  readMore={gettext('Les mer')}
                  readMoreUri="/refinansiering"
                  priceBottomText={gettext('i gjennomsnitt')}
                  orderButtonText={gettext('Søk nå')}
                />
              </Grid.Column>
            </div>
          </Container>
        </div>
        <div className={styles.information}>
          <Container>
            <Card fluid className="help-card big-help">
              <div className="content">
                <Grid stackable doubling>
                  <div className="ten wide tablet seven wide computer column">
                    <h4 className="help-title">
                      {gettext('Do you')}{' '}
                      <strong>{gettext('need help?')}</strong>
                    </h4>
                    <p>
                      {renderHTML(
                        gettext(
                          'Vår kundesupport er åpen hverdager fra 9-16 og står klare til å bistå deg! ring oss på',
                        ),
                      )}
                      <br />
                      <a
                        type="tel"
                        href="tel:21415600"
                        style={{
                          color: '#3f2a56',
                          fontSize: 22,
                          marginLeft: 5,
                          fontWeight: 'bold',
                        }}
                      >
                        21 41 56 00
                      </a>
                    </p>
                  </div>
                  <div className="six wide tablet eight wide computer column right aligned">
                    <img
                      alt="table with remote and coffe cup"
                      src={`${process.env.PUBLIC_URL}/media/section-help-table.png`}
                    />
                  </div>
                </Grid>
              </div>
            </Card>
          </Container>
        </div>
      </div>
    );
  }
}
export default withRouter(
  connect(
    (state) => ({
      bob: state.global.bob,
      config: state.global.config,
      products: state.global.entities.products,
      chat: state.global.bob.chatElements,
      providers: state.global.providers,
    }),
    (dispatch) => ({
      actions: Object.assign(
        {},
        { dispatch },
        bindActionCreators(
          {
            ...authAPI.actions,

            resetBob,
          },
          dispatch,
        ),
      ),
    }),
  )(Overview),
);
