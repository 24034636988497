export default [
  {
    name: 'Samle smålån og kredittkort',
    url: 'https://hudya.no/nb/refinansiering/samle-smalan-og-kredittkort/'
  },
  {
    name: 'Lån uten sikkerhet',
    url: 'https://hudya.no/nb/refinansiering/lan-uten-sikkerhet/'
  },
  {
    name: 'Det er stor forskjell på rimelig og dyrt forbrukslån',
    url:
      'https://hudya.no/nb/refinansiering/det-er-stor-forskjell-pa-rimelig-og-dyrt-forbrukslan/'
  },
  {
    name: 'Nye retningslinjer for forbrukslån',
    url:
      'https://hudya.no/nb/refinansiering/nye-retningslinjer-for-forbrukslan/'
  }
]
