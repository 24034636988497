import React from 'react'
import PropTypes from 'prop-types'
import ReactMarkdown from 'react-markdown/with-html'

import { Button, Icon, Grid, Container, Card } from 'semantic-ui-react'
import styles from './subscriptionSection.module.css'
import SubscriptionCard from '../SubscriptionCard'
import Chat from '../../../../../components/Chat/modal'
import Carousel from '@brainhubeu/react-carousel'
import '@brainhubeu/react-carousel/lib/style.css'
const SubscriptionSection = (props, context) => {
  const { gettext, xgettext } = context
  return (
    <div className="mobile-plans ">
      <Container className="mobile hidden">
        <div className="ui stackable doubling four column grid center aligned">
          <Grid.Column>
            <SubscriptionCard
              isFlex={true}
              callTimeIncluded="0"
              mbInclided="0"
              dataSize="MB"
              price="0"
              goalFlow="mobile_onboarding"
              name="Flex Zero"
              step="init"
              link="flex"
            />
          </Grid.Column>

          <Grid.Column>
            <SubscriptionCard
              callTimeIncluded="50"
              mbInclided="0"
              dataSize="MB"
              price="49"
              goalFlow="mobile_onboarding"
              name="0 MB"
              step="init"
              link="0mb"
            />
          </Grid.Column>

          <Grid.Column>
            <SubscriptionCard
              callTimeIncluded="150"
              mbInclided="300"
              dataSize="MB"
              price="89"
              goalFlow="mobile_onboarding"
              name="300 MB"
              step="init"
              link="300mb"
            />
          </Grid.Column>

          <Grid.Column>
            <SubscriptionCard
              callTimeIncluded="Fri"
              mbInclided="500"
              dataSize="MB"
              price="109"
              goalFlow="mobile_onboarding"
              name="500 MB"
              step="init"
              link="500mb"
            />
          </Grid.Column>
          <Grid.Column>
            <SubscriptionCard
              isChild
              callTimeIncluded="Fri"
              mbInclided="1"
              dataSize="GB"
              price="119"
              goalFlow="mobile_onboarding"
              name="Barn 1 GB"
              step="init"
              link="barn-1gb"
            />
          </Grid.Column>
          <Grid.Column>
            <SubscriptionCard
              callTimeIncluded="Fri"
              mbInclided="1"
              dataSize="GB"
              price="149"
              goalFlow="mobile_onboarding"
              name="1 GB"
              step="init"
              link="1gb"
            />
          </Grid.Column>
          <Grid.Column>
            <SubscriptionCard
              callTimeIncluded="Fri"
              mbInclided="2"
              dataSize="GB"
              price="199"
              goalFlow="mobile_onboarding"
              name="2 GB"
              step="init"
              link="2gb"
            />
          </Grid.Column>
          <Grid.Column>
            <SubscriptionCard
              callTimeIncluded="Fri"
              mbInclided="3"
              dataSize="GB"
              price="249"
              goalFlow="mobile_onboarding"
              name="3 GB"
              step="init"
              link="3gb"
            />
          </Grid.Column>
          <Grid.Column>
            <SubscriptionCard
              callTimeIncluded="Fri"
              mbInclided="5"
              dataSize="GB"
              price="279"
              goalFlow="mobile_onboarding"
              name="5 GB"
              step="init"
              link="5gb"
            />
          </Grid.Column>
          <Grid.Column>
            <SubscriptionCard
              callTimeIncluded="Fri"
              mbInclided="6"
              dataSize="GB"
              price="299"
              goalFlow="mobile_onboarding"
              name="6 GB"
              step="init"
              link="6gb"
            />
          </Grid.Column>
          <Grid.Column>
            <SubscriptionCard
              callTimeIncluded="Fri"
              mbInclided="10"
              dataSize="GB"
              price="349"
              goalFlow="mobile_onboarding"
              name="10 GB"
              step="init"
              link="10gb"
            />
          </Grid.Column>
          <Grid.Column>
            <SubscriptionCard
              callTimeIncluded="Fri"
              mbInclided="12"
              dataSize="GB"
              price="379"
              goalFlow="mobile_onboarding"
              name="12 GB"
              step="init"
              link="12gb"
            />
          </Grid.Column>
          <Grid.Column>
            <SubscriptionCard
              callTimeIncluded="Fri"
              mbInclided="20"
              dataSize="GB"
              price="449"
              goalFlow="mobile_onboarding"
              name="20 GB"
              step="init"
              link="20gb"
            />
          </Grid.Column>
          <Grid.Column>
            <SubscriptionCard
              callTimeIncluded="Fri"
              mbInclided="30"
              dataSize="GB"
              price="549"
              goalFlow="mobile_onboarding"
              name="30 GB"
              step="init"
              link="30gb"
            />
          </Grid.Column>
          <Grid.Column>
            <SubscriptionCard
              callTimeIncluded="0"
              mbInclided="0"
              dataSize="GB"
              price="0"
              goalFlow="mobile_onboarding"
              name="Mobilt bredbånd"
              step="init"
              link="mobilt-bredband"
            />
          </Grid.Column>
          <Grid.Column>
            <Card fluid className="help-card">
              <div className="content">
                <h3 className="help-title">
                  {gettext('Do you')} <strong>{gettext('need help?')}</strong>
                </h3>
                <Chat
                  goalFlow="mobile_onboarding"
                  step="mobile_call_me"
                  buttonText={gettext('Click here for support')}
                  buttonSize="large"
                />

                <img
                  src={process.env.PUBLIC_URL + '/media/section-help-table.png'}
                />
              </div>
            </Card>
          </Grid.Column>
        </div>
      </Container>

      <Container className="mobile only center aligned">
        <Carousel
          addArrowClickHandler
          arrowLeft={
            <Icon className="icon-example" name="angle left" size="large" />
          }
          arrowRight={
            <Icon className="icon-example" name="angle right" size="large" />
          }
          infinite
        >
          <SubscriptionCard
            isFlex={true}
            callTimeIncluded="0"
            mbInclided="0"
            dataSize="MB"
            price="0"
            goalFlow="mobile_onboarding"
            name="Flex Zero"
            step="init"
            link="flex"
          />

          <SubscriptionCard
            callTimeIncluded="50"
            mbInclided="0"
            dataSize="MB"
            price="49"
            goalFlow="mobile_onboarding"
            name="0 MB"
            step="init"
            link="0mb"
          />
          <SubscriptionCard
            callTimeIncluded="150"
            mbInclided="300"
            dataSize="MB"
            price="89"
            goalFlow="mobile_onboarding"
            name="300 MB"
            step="300mb"
          />
          <SubscriptionCard
            callTimeIncluded="Fri"
            mbInclided="500"
            dataSize="MB"
            price="109"
            goalFlow="mobile_onboarding"
            name="500 MB"
            step="init"
            link="500mb"
          />
          <SubscriptionCard
            callTimeIncluded="Fri"
            mbInclided="1"
            dataSize="GB"
            price="119"
            goalFlow="mobile_onboarding"
            name="Barn 1 GB"
            step="init"
            link="barn-1gb"
          />
          <SubscriptionCard
            callTimeIncluded="Fri"
            mbInclided="1"
            dataSize="GB"
            price="149"
            goalFlow="mobile_onboarding"
            name="1 GB"
            step="init"
            link="1gb"
          />

          <SubscriptionCard
            callTimeIncluded="Fri"
            mbInclided="2"
            dataSize="GB"
            price="199"
            goalFlow="mobile_onboarding"
            name="2 GB"
            step="init"
            link="2gb"
          />
          <SubscriptionCard
            callTimeIncluded="Fri"
            mbInclided="3"
            dataSize="GB"
            price="249"
            goalFlow="mobile_onboarding"
            name="3 GB"
            step="init"
            link="3gb"
          />
          <SubscriptionCard
            callTimeIncluded="Fri"
            mbInclided="5"
            dataSize="GB"
            price="279"
            goalFlow="mobile_onboarding"
            name="5 GB"
            step="init"
            link="5gb"
          />
          <SubscriptionCard
            callTimeIncluded="Fri"
            mbInclided="6"
            dataSize="GB"
            price="299"
            goalFlow="mobile_onboarding"
            name="6 GB"
            step="init"
            link="6gb"
          />
          <SubscriptionCard
            callTimeIncluded="Fri"
            mbInclided="10"
            dataSize="GB"
            price="349"
            goalFlow="mobile_onboarding"
            name="10 GB"
            step="init"
            link="10gb"
          />
          <SubscriptionCard
            callTimeIncluded="Fri"
            mbInclided="12"
            dataSize="GB"
            price="379"
            goalFlow="mobile_onboarding"
            name="12 GB"
            step="init"
            link="12gb"
          />
          <SubscriptionCard
            callTimeIncluded="Fri"
            mbInclided="20"
            dataSize="GB"
            price="449"
            goalFlow="mobile_onboarding"
            name="20 GB"
            step="init"
            link="20gb"
          />
          <SubscriptionCard
            callTimeIncluded="Fri"
            mbInclided="30"
            dataSize="GB"
            price="549"
            goalFlow="mobile_onboarding"
            name="30 GB"
            step="init"
            link="30gb"
          />
          <SubscriptionCard
            callTimeIncluded="0"
            mbInclided="0"
            dataSize="GB"
            price="0"
            goalFlow="mobile_onboarding"
            name="Mobilt bredbånd"
            step="init"
            link="mobilt-bredband"
          />
        </Carousel>
      </Container>
    </div>
  )
}

SubscriptionSection.contextTypes = {
  gettext: PropTypes.func.isRequired,
  xgettext: PropTypes.func.isRequired,
}

export default SubscriptionSection
