import React from 'react'
import PropTypes from 'prop-types'
import { Grid, Container, Message } from 'semantic-ui-react'
import ReactMarkdown from 'react-markdown/with-html'
import renderHTML from 'react-render-html'

import styles from './topSection.module.css'
import Chat from '../../../../../components/Chat'
import ChatWidget from '../../../../../components/ChatWidget'

const TopSection = (props, context) => {
  const { gettext, xgettext } = context

  document.title = xgettext(
    'Hudya <strong>Mobile</strong> Products',
    'mobile main header products'
  ).replace(/<[^>]*>?/gm, '')

  return (
    <header className={styles.header}>
      <Container>
        <Grid stackable>
          <Grid.Row textAlign="left">
            <Grid.Column>
              <p>
                <i>{gettext('Life Simplified')}</i>
              </p>
              <h1>
                {renderHTML(
                  xgettext(
                    'Hudya <strong>Mobile</strong> Products',
                    'mobile main header products'
                  )
                )}
              </h1>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    </header>
  )
}
TopSection.contextTypes = {
  gettext: PropTypes.func.isRequired,
  xgettext: PropTypes.func.isRequired,
}
export default TopSection
