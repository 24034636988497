import React, { useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

const WPContext = React.createContext(undefined);
const { Consumer: WPConsumer } = WPContext;

const WP_API_URL = 'https://wp.hudya.no';

const pages = {
  apiUrl: 'wp-json/acf/v3/pages',
  customerService: 3480,
};

function WPProvider({ children }) {
  const [language, setLanguage] = useState('nb');

  const apiCall = async (url) => {
    const data = {
      status: null,
      response: null,
    };
    await axios
      .get(url, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((res) => {
        if (res.status === 200) {
          data.response = res.data.acf;
          data.status = res.status;
        }
      })
      .catch((err) => console.log(err));

    return data;
  };

  const getPage = async (name) => {
    const res = await apiCall(
      `${WP_API_URL}/${language}/${pages.apiUrl}/${pages[name]}/`,
    );
    return res;
  };

  const value = {
    getPage,
    setLanguage,
    language,
  };

  return <WPContext.Provider value={value}>{children}</WPContext.Provider>;
}

WPProvider.propTypes = {
  children: PropTypes.node,
};

WPProvider.defaultProps = {
  children: null,
};

export { WPContext, WPConsumer, WPProvider };
