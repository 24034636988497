import React from 'react'
import ReactMarkdown from 'react-markdown/with-html'
import { CSSTransition } from 'react-transition-group'
import PropTypes from 'prop-types'

const Utterance = (props) => (
  <CSSTransition
    appear
    classNames="utterance"
    in={true}
    timeout={{
      enter: 1000,
      exit: 1000,
    }}
    unmountOnExit
  >
    <div
      className={
        props.inputType === 'iframe'
          ? 'chat-bubble-iframe'
          : props.userResponse
          ? 'chat-bubble-user'
          : 'chat-bubble'
      }
    >
      <div className="loading">
        <div className="dot dot--one" />
        <div className="dot dot--two" />
        <div className="dot dot--three" />
      </div>
      {props.inputType === 'text' && (
        <ReactMarkdown
          source={props.utterance}
          escapeHtml={false}
          linkTarget="_blank"
        />
      )}
      {props.inputType === 'iframe' && (
        <iframe
          src={props.utterance + '&localv=1'}
          title="Gjeldsregisteret"
          width="100%"
          height="100%"
        ></iframe>
      )}
    </div>
  </CSSTransition>
)

Utterance.propTypes = {
  userResponse: PropTypes.bool,
  utterance: PropTypes.string.isRequired,
}
Utterance.defaltProps = {
  userResponse: false,
}
export default Utterance
