export default [
  {
    name: 'Hva er det billigste mobilabonnementet fra Hudya?',
    url:
      'https://hudya.no/nb/mobil/hva-er-det-billigste-mobilabonnementet-fra-hudya/'
  },
  {
    name: 'Hvordan oppretter jeg eFaktura??',
    url: 'https://hudya.no/nb/mobil/efaktura/'
  },
  {
    url: 'https://hudya.no/nb/mobil/hva-er-mobilabonnement-for-barn/',
    name: 'Hva er mobilabonnement for barn?'
  },
  {
    name: 'Hva er datakontroll?',
    url: 'https://hudya.no/nb/mobil/hva-er-datakontroll/'
  },
  {
    name: 'Hvordan unngå å bli belastet for overforbruk av mobildata?',
    url:
      'https://hudya.no/nb/mobil/hvordan-unnga-a-bli-belastet-for-overforbruk-av-mobildata/'
  },
  {
    name: 'Hva er overforbruk av mobildata?',
    url: 'https://hudya.no/nb/mobil/hva-er-overforbruk-av-mobildata/'
  },

  {
    name: 'Hvordan fungerer abonnementet i EU og EØS?',
    url:
      'https://hudya.no/nb/telipol/hvordan-fungerer-abonnementet-i-eu-og-eos/'
  },
  {
    name: 'Har Hudya Mobil AvtaleGiro?',
    url: 'https://hudya.no/nb/telipol/faktura-forfall-og-betalingsinformasjon/'
  },
  {
    name: 'Hvor finner jeg faktura på nett?',
    url: 'https://hudya.no/nb/telipol/hvorfor-finner-jeg-faktura-pa-nett/'
  },
  {
    name: 'Hvordan melder jeg eierskifte på et mobilabonnement?',
    url: 'https://hudya.no/nb/mobil/hvor-finner-jeg-eierskifteskjema/'
  },
  {
    name: 'Hvordan bestiller jeg ekstra data?',
    url:
      'https://hudya.no/nb/telipol/hvordan-bestiller-jeg-ekstra-data-hos-hudya/'
  },
  {
    name: 'Hvordan åpner jeg et sperret abonnement?',
    url:
      'https://hudya.no/nb/telipol/abonnementet-mitt-er-sperret-grunnet-manglende-betaling-hvordan-apner-jeg-det/'
  },
  {
    name: 'Hvordan fungerer Data Rollover?',
    url: 'https://hudya.no/nb/telipol/hvordan-fungerer-data-rollover/'
  },
  {
    name: 'Hvorfor er det dyrt å bruke mobildata på ferge?',
    url:
      'https://hudya.no/nb/mobil/hvorfor-er-det-dyrt-a-bruke-mobildata-pa-ferge/'
  },
  {
    name: 'Hvorfor skal jeg slå av mobildata på ferge?',
    url: 'https://hudya.no/nb/mobil/hvorfor-skal-jeg-sla-av-mobildata-pa-ferge/'
  },
  {
    name: 'Hvor finner jeg vilkårene for Hudya Mobil?',
    url: 'https://hudya.no/nb/mobil/hvor-finner-jeg-vilkarene-for-hudya-mobil/'
  },
  {
    name:
      'Har kjøpt datapakke utland – hvorfor får jeg SMS fra 1999 om at jeg nærmer meg beløpsgrense utland?',
    url:
      'https://hudya.no/nb/telipol/har-kjopt-datapakke-utland-hvorfor-far-jeg-sms-fra-1999-om-at-jeg-naermer-meg-belopsgrense-utland/'
  },

  {
    name: ' Jeg er belastet for servicetjenester. Hva er det?',
    url:
      '	https://hudya.no/nb/telipol/jeg-er-belastet-for-servicetjenester-hva-er-det/'
  },
  {
    name: '  Jeg er belastet for innholdstjenester. Hva er det?',
    url:
      '	https://hudya.no/nb/telipol/jeg-er-belastet-for-innholdstjenester-hva-er-det/'
  },
  {
    name: '  Jeg er ny kunde. Hvorfor er første faktura høyere enn forventet?',
    url: '	https://hudya.no/nb/telipol/forste-faktura-er-hoyere-enn-forventet/'
  },
  {
    name:
      '  Jeg kobles ikke automatisk til et mobilt nettverk i utlandet. Hva gjør jeg?',
    url:
      '	https://hudya.no/nb/mobil/jeg-kobles-ikke-automatisk-til-et-nett-i-utlandet-hva-gjor-jeg/'
  },
  {
    name: '   Hvordan oppretter jeg BankID på mobil-avtale?',
    url:
      '	https://hudya.no/nb/mobil/hvordan-oppretter-jeg-bankid-pa-mobil-avtale/'
  },
  {
    name: 'Hvordan benytter jeg meg av angreretten?',
    url:
      'https://hudya.no/nb/mobil/jeg-vil-benytte-meg-av-angreretten-hva-gjor-jeg/'
  },
  {
    name: 'Hva er Sone 2-pakke?',
    url: 'https://hudya.no/nb/telipol/hvordan-fungerer-sone-2-pakken/'
  },
  {
    name: 'Får jeg varsling på Ring utland-pakken?',
    url:
      'https://hudya.no/nb/telipol/far-jeg-varsling-nar-jeg-har-brukt-opp-ringeminuttene-i-ring-utland-pakken/'
  },
  {
    name: 'Hvorfor er jeg belastet for overforbruk av data?',
    url:
      'https://hudya.no/nb/telipol/hvorfor-er-jeg-belastet-for-overforbruk-av-data/'
  },
  {
    name: 'Hvordan klager jeg på faktura eller tjenester levert av Hudya?',
    url:
      'https://hudya.no/nb/telipol/hvordan-klager-jeg-pa-faktura-eller-tjenester-levert-av-hudya/'
  }
]
