export default [
  {
    name: 'Hva er beste strømpris?',
    url: 'https://hudya.no/nb/strom-priser/hva-er-beste-strompris/',
    locale: 'nb_NO'
  },
  {
    name: 'Les mer om våre priser',
    url: 'https://hudya.no/nb/strom-priser/',
    locale: 'nb_NO'
  },
  {
    name: 'Hva er spotpris på strøm?',
    url: 'https://hudya.no/nb/strom-priser/hva-er-spotpris-pa-strom/',
    locale: 'nb_NO'
  },

  {
    name: 'Hva er forskjellen på strøm og nettleie?',
    url:
      'https://hudya.no/nb/strom-priser/hva-er-forskjellen-pa-strom-og-nettleie/',
    locale: 'nb_NO'
  },
  {
    name: 'Hva er en god strømleverandør?',
    url: 'https://hudya.no/nb/strom-priser/hva-er-en-god-stromleverandor/',
    locale: 'nb_NO'
  },
  {
    name: 'Hva er billig strøm?',
    url: 'https://hudya.no/nb/strom-priser/hva-er-billig-strom/',
    locale: 'nb_NO'
  },
  {
    name: 'Hvorfor er Elhub nyttig for meg?',
    url: 'https://hudya.no/nb/strom-priser/hvorfor-er-elhub-nyttig-for-meg/',
    locale: 'nb_NO'
  },
  {
    name: 'Hva er Elhub?',
    url: 'https://hudya.no/nb/strom-priser/hva-er-elhub/',
    locale: 'nb_NO'
  },
  {
    name: 'Hvorfor får jeg nettleiefaktura fra nettselskapet?',
    url:
      'https://hudya.no/nb/strom-priser/hvorfor-far-jeg-nettleiefaktura-fra-nettselskapet/',
    locale: 'nb_NO'
  },
  {
    name: 'Hva er Målepunkt-ID?',
    url: 'https://hudya.no/nb/strom-priser/hva-er-malepunkt-id/',
    locale: 'nb_NO'
  },
  {
    name: 'Hva gjør Hudya til en unik strømleverandør?',
    url:
      'https://hudya.no/nb/strom-priser/hva-gjor-hudya-til-en-unik-stromleverandor/',
    locale: 'nb_NO'
  },
  {
    name: 'Hvorfor skal jeg velge Hudya Strøm?',
    url: 'https://hudya.no/nb/strom-priser/hvorfor-skal-jeg-velge-hudya/',
    locale: 'nb_NO'
  },
  {
    name: 'Jeg angrer min bestilling av Hudya Strøm, hva gjør jeg?',
    url:
      'https://hudya.no/nb/strom-priser/jeg-angrer-min-bestilling-av-hudya-strom-hva-gjor-jeg/',
    locale: 'nb_NO'
  },
  {
    name: 'Hvordan regner Hudya ut min strømpris?',
    url:
      'https://hudya.no/nb/strom-priser/hvordan-regner-hudya-ut-min-strompris/',
    locale: 'nb_NO'
  },
  {
    name: 'Hvor kommer strømmen Hudya leverer fra?',
    url:
      'https://hudya.no/nb/strom-priser/hvor-kommer-strommen-hudya-leverer-fra/',
    locale: 'nb_NO'
  },
  {
    name: 'Hvorfor skal jeg ta meg tid til å bytte strømleverandør til Hudya?',
    url:
      'https://hudya.no/nb/strom-priser/hvorfor-skal-jeg-ta-meg-tid-til-a-bytte-stromleverandor-til-hudya/',
    locale: 'nb_NO'
  },
  {
    name: 'Hvordan bytter jeg strømleverandør, og er det vanskelig?	',
    url:
      'https://hudya.no/nb/strom-priser/hvordan-bytter-jeg-stromleverandor-og-er-det-vanskelig/',
    locale: 'nb_NO'
  },
  {
    name: 'Vil jeg miste strømmen under bytte av leverandør?',
    url:
      'https://hudya.no/nb/strom-priser/vil-jeg-miste-strommen-under-bytte-av-leverandor/',
    locale: 'nb_NO'
  },
  {
    name:
      'Hvordan finner jeg ut av vilkårene jeg har med nåværende strømleverandør?',
    url:
      'https://hudya.no/nb/strom-priser/hvordan-finner-jeg-ut-av-vilkarene-jeg-har-med-navaerende-stromleverandor/',
    locale: 'nb_NO'
  },
  {
    name: 'Hva er bindingstiden på min kontrakt for Hudya Strøm?',
    url:
      'https://hudya.no/nb/strom-priser/hva-er-bindingstiden-pa-min-kontrakt/',
    locale: 'nb_NO'
  },
  {
    name: 'Hva må jeg gjøre for å si opp strømavtalen min hos Hudya?',
    url:
      'https://hudya.no/nb/strom-priser/hva-ma-jeg-gjore-for-a-si-opp-stromavtalen-min-hos-hudya/',
    locale: 'nb_NO'
  },
  {
    name: 'Jeg skal flytte, hvordan gjør vi det med min Hudya-strøm da?',
    url:
      'https://hudya.no/nb/strom-priser/jeg-skal-flytte-hvordan-gjor-vi-det-med-min-hudya-strom-da/',
    locale: 'nb_NO'
  },
  {
    name: 'Hvordan betaler jeg for strøm fra Hudya?',
    url:
      'https://hudya.no/nb/strom-priser/hvordan-betaler-jeg-for-strom-fra-hudya/',
    locale: 'nb_NO'
  },
  {
    name: 'Hva vil strømmen koste?',
    url: 'https://hudya.no/nb/strom-priser/hva-vil-det-koste/',
    locale: 'nb_NO'
  },
  {
    name:
      'Vil Hudya kutte strømmen min dersom jeg glemmer å betale en regning?',
    url:
      'https://hudya.no/nb/strom-priser/vil-hudya-kutte-strommen-min-dersom-jeg-glemmer-a-betale-en-regning/',
    locale: 'nb_NO'
  },
  {
    name:
      'Varför ska jag välja el märkt Bra Miljöval, hur påverkar det vårt samhälle?',
    url:
      'https://hudya.se/sv/produkter/el/varfor-ska-jag-valja-el-markt-bra-miljoval-hur-paverkar-det-vart-samhalle/',
    locale: 'sv_SE'
  },
  {
    name: 'Vad ingår i den totala elkostnaden?',
    url: 'https://hudya.se/sv/produkter/el/vad-ingar-i-den-totala-elkostnaden/',
    locale: 'sv_SE'
  },
  {
    name: 'Vad har ni för bindningstider?',
    url: 'https://hudya.se/sv/produkter/el/vad-har-ni-for-bindningstider/',
    locale: 'sv_SE'
  },
  {
    name: 'Hur kommer det sig att Hudya inte tar någon fast månadsavgift?',
    url:
      'https://hudya.se/sv/produkter/el/hur-kommer-det-sig-att-hudya-inte-tar-nagon-fast-manadsavgift/',
    locale: 'sv_SE'
  },
  {
    name: 'Vad är skillnaden mellan Elnät och Elhandel?',
    url:
      'https://hudya.se/sv/produkter/el/vad-ar-skillnaden-mellan-elnat-och-elhandel/',
    locale: 'sv_SE'
  },
  {
    name: 'Vad går elnätsavgiften till?',
    url: 'https://hudya.se/sv/produkter/el/vad-gar-elnatsavgiften-till/',
    locale: 'sv_SE'
  },
  {
    name: 'Kan jag flytta elhandelsavtalet till min nya adress?',
    url:
      'https://hudya.se/sv/produkter/el/kan-jag-flytta-elhandelsavtalet-till-min-nya-adress/',
    locale: 'sv_SE'
  },
  {
    name: 'Kan jag få en månadssammanställning skickad till min e-postadress?',
    url:
      'https://hudya.se/sv/produkter/el/kan-jag-fa-en-manadssammanstallning-skickad-till-min-e-postadress/',
    locale: 'sv_SE'
  },
  {
    name: 'Jag har strömavbrott, när fungerar elen igen?',
    url:
      'https://hudya.se/sv/produkter/el/jag-har-stromavbrott-nar-fungerar-elen-igen/',
    locale: 'sv_SE'
  },
  {
    name: 'Hur blir jag kund hos Hudya?',
    url: 'https://hudya.se/sv/produkter/el/hur-blir-jag-kund-hos-hudya/',
    locale: 'sv_SE'
  },
  {
    name:
      'Jag ska hyra ut min bostad i andra hand, hur påverkar det mitt elavtal?',
    url:
      'https://hudya.se/sv/produkter/el/jag-ska-hyra-ut-min-bostad-i-andra-hand-hur-paverkar-det-mitt-elavtal/',
    locale: 'sv_SE'
  },
  {
    name: 'Hur säger jag upp mitt avtal?',
    url: 'https://hudya.se/sv/produkter/el/hur-sager-jag-upp-mitt-avtal/',
    locale: 'sv_SE'
  },
  {
    name: 'Hur mycket betalar jag för elen från Hudya?',
    url:
      'https://hudya.se/sv/produkter/el/hur-mycket-betalar-jag-for-elen-fran-hudya/',
    locale: 'sv_SE'
  },
  {
    name: 'Varför ska jag lägga ner tid på att byta elavtal?',
    url:
      'https://hudya.se/sv/produkter/el/varfor-ska-jag-lagga-ner-tid-pa-att-byta-elavtal/',
    locale: 'sv_SE'
  },
  {
    name: 'Vad för betalningsalternativ erbjuder ni?',
    url:
      'https://hudya.se/sv/produkter/el/vad-for-betalningsalternativ-erbjuder-ni/',
    locale: 'sv_SE'
  }
]
