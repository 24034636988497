import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { Accordion, Button, Grid, Icon, Menu, Sidebar } from 'semantic-ui-react'
import { Link, withRouter } from 'react-router-dom'

import WelcomeBanner from '../../components/GlobalMessage/WelcomeBanner'
import Footer from '../Layout/Footer'
import config from '../../config'
import { setDefaultLocale } from '../../store/global/config'

const MenuItem = Menu.Item
const Pushable = Sidebar.Pushable
const Pusher = Sidebar.Pusher

class PushMenuWrapper extends Component {
  static contextTypes = {
    gettext: PropTypes.func.isRequired,
    xgettext: PropTypes.func.isRequired,
  }

  static propTypes = {
    actions: PropTypes.shape({ dispatch: PropTypes.func }).isRequired,
    children: PropTypes.object.isRequired,
    credentials: PropTypes.object.isRequired,
    navigation: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
  }

  constructor(props) {
    super(props)
    this.state = { activeIndex: -1 }
    this.handleLogoutItemClick = this.handleLogoutItemClick.bind(this)
    this.handleAccorionClick = this.handleAccorionClick.bind(this)
  }

  handleLogoutItemClick() {
    const { dispatch } = this.props.actions
    const logout = () => ({ type: 'LOGOUT' })

    dispatch(logout())
  }
  handleAccorionClick = (e, titleProps) => {
    e.preventDefault()

    const { index } = titleProps
    const { activeIndex } = this.state
    const newIndex = activeIndex === index ? -1 : index

    this.setState({ activeIndex: newIndex })
  }

  render() {
    // regex to match the first part of the loacation path value
    const re = /\/(.*?)\//
    let locationPathPart = re.exec(this.props.location.pathname)

    locationPathPart = locationPathPart ? locationPathPart[0] : '/'
    const { gettext, xgettext } = this.context

    const { defaultLocale, activeProducts, urlLocaleMap } = this.props.config
    const wpSiteUrl = config.WP_SITE_URL[defaultLocale]
    const { credentials } = this.props

    // search routes to find a matching lacationPathPart. this is a workaround to support params in the urls
    const pathObj = this.props.routes.filter((item) =>
      item.path.includes(locationPathPart)
    )[0]
    const fullscreen = pathObj ? pathObj.fullscreen : false

    return (
      <Pushable style={{ minHeight: '95vh' }}>
        <Sidebar
          animation="overlay"
          as={Menu}
          borderless
          direction="top"
          inverted
          vertical
          visible={this.props.navigation.pushMenu.isToggled}
        >
          <div className="ui center aligned grid container">
            <MenuItem as="a" href="/">
              {xgettext('Home', 'Text for the link to the main Hudya page.')}
            </MenuItem>

            {/* <MenuItem
              as="a"
              disabled={!activeProducts.mobile[defaultLocale]}
              href={"/mobil/"}
            >
              {xgettext("Mobile", "Text for the link to Hudya Mobile.")}
            </MenuItem> */}
            {activeProducts.mobile[defaultLocale] && (
              <Accordion
                as={MenuItem}
                hidden={activeProducts.mobile[defaultLocale]}
              >
                <Accordion.Title
                  active={this.state.activeIndex === 0}
                  index={0}
                  style={{ color: '#bbb' }}
                  onClick={this.handleAccorionClick}
                >
                  {gettext('Mobil')}
                  <Icon name="dropdown" />
                </Accordion.Title>
                <Accordion.Content
                  active={this.state.activeIndex === 0}
                  style={{
                    backgroundColor: 'rgba(255, 255, 255, 0.1)',
                    borderRadius: '6px',
                    marginLeft: 10,
                    marginRight: 10,
                  }}
                >
                  <MenuItem
                    as="a"
                    href={'/mobilabonnement/'}
                    disabled={!activeProducts.mobile[defaultLocale]}
                  >
                    {gettext('Mobil')}
                  </MenuItem>
                  <MenuItem
                    as="a"
                    href={'/mobilabonnement/flex/'}
                    disabled={!activeProducts.mobile[defaultLocale]}
                  >
                    {xgettext(
                      'Flex',
                      'Text for the link to Hudya Mobile Flex.'
                    )}
                  </MenuItem>
                  <MenuItem
                    as="a"
                    href={'/mobilabonnement/0mb/'}
                    disabled={!activeProducts.mobile[defaultLocale]}
                  >
                    {xgettext('0GB', 'Text for the link to Hudya Mobile 0GB.')}
                  </MenuItem>
                  <MenuItem
                    as="a"
                    href={'/mobilabonnement/300mb/'}
                    disabled={!activeProducts.mobile[defaultLocale]}
                  >
                    {xgettext(
                      '300MB',
                      'Text for the link to Hudya Mobile 300mb.'
                    )}
                  </MenuItem>
                  <MenuItem
                    as="a"
                    href={'/mobilabonnement/500mb/'}
                    disabled={!activeProducts.mobile[defaultLocale]}
                  >
                    {xgettext(
                      '500MB',
                      'Text for the link to Hudya Mobile 500mb.'
                    )}
                  </MenuItem>
                  <MenuItem
                    as="a"
                    href={'/mobilabonnement/barn-1gb/'}
                    disabled={!activeProducts.mobile[defaultLocale]}
                  >
                    {xgettext(
                      'Barn 1GB',
                      'Text for the link to Hudya Mobile Barn 1GB.'
                    )}
                  </MenuItem>
                  <MenuItem
                    as="a"
                    href={'/mobilabonnement/1gb/'}
                    disabled={!activeProducts.mobile[defaultLocale]}
                  >
                    {xgettext('1GB', 'Text for the link to Hudya Mobile 1GB.')}
                  </MenuItem>
                  <MenuItem
                    as="a"
                    href={'/mobilabonnement/2gb/'}
                    disabled={!activeProducts.mobile[defaultLocale]}
                  >
                    {xgettext('2GB', 'Text for the link to Hudya Mobile 2gb.')}
                  </MenuItem>
                  <MenuItem
                    as="a"
                    href={'/mobilabonnement/3gb/'}
                    disabled={!activeProducts.mobile[defaultLocale]}
                  >
                    {xgettext('3GB', 'Text for the link to Hudya Mobile 3gb.')}
                  </MenuItem>
                  <MenuItem
                    as="a"
                    href={'/mobilabonnement/5gb/'}
                    disabled={!activeProducts.mobile[defaultLocale]}
                  >
                    {xgettext('5GB', 'Text for the link to Hudya Mobile 5gb.')}
                  </MenuItem>
                  <MenuItem
                    as="a"
                    href={'/mobilabonnement/6gb/'}
                    disabled={!activeProducts.mobile[defaultLocale]}
                  >
                    {xgettext('6GB', 'Text for the link to Hudya Mobile 6gb.')}
                  </MenuItem>
                  <MenuItem
                    as="a"
                    href={'/mobilabonnement/10gb/'}
                    disabled={!activeProducts.mobile[defaultLocale]}
                  >
                    {xgettext(
                      '10GB',
                      'Text for the link to Hudya Mobile 10gb.'
                    )}
                  </MenuItem>
                  <MenuItem
                    as="a"
                    href={'/mobilabonnement/12gb/'}
                    disabled={!activeProducts.mobile[defaultLocale]}
                  >
                    {xgettext(
                      '12GB',
                      'Text for the link to Hudya Mobile 12gb.'
                    )}
                  </MenuItem>
                  <MenuItem
                    as="a"
                    href={'/mobilabonnement/20gb/'}
                    disabled={!activeProducts.mobile[defaultLocale]}
                  >
                    {xgettext(
                      '20GB',
                      'Text for the link to Hudya Mobile 20gb.'
                    )}
                  </MenuItem>
                  <MenuItem
                    as="a"
                    href={'/mobilabonnement/30gb/'}
                    disabled={!activeProducts.mobile[defaultLocale]}
                  >
                    {xgettext(
                      '30GB',
                      'Text for the link to Hudya Mobile 30gb.'
                    )}
                  </MenuItem>
                  <MenuItem
                    as="a"
                    href={'/mobilabonnement/mobilt-bredband/'}
                    disabled={!activeProducts.mobile[defaultLocale]}
                  >
                    {xgettext(
                      'Mobilt bredbånd',
                      'Text for the link to Hudya Mobile mobilt bredbånd.'
                    )}
                  </MenuItem>
                  <MenuItem
                    as="a"
                    href={'/mobilabonnement/ovrige-priser-alle-abonnement'}
                    disabled={!activeProducts.mobile[defaultLocale]}
                  >
                    {xgettext(
                      'Øvrige priser',
                      'Text for the link to Hudya Mobile prices.'
                    )}
                  </MenuItem>{' '}
                  <MenuItem
                    as="a"
                    href={'http://shop.hudya.no'}
                    disabled={!activeProducts.mobile[defaultLocale]}
                  >
                    {xgettext(
                      'Shop',
                      'Text for the link to Hudya Mobile shop.'
                    )}
                  </MenuItem>
                </Accordion.Content>
              </Accordion>
            )}
            {/* {defaultLocale !== 'sv_SE' && (
              <MenuItem
                as="a"
                disabled={!activeProducts.power[defaultLocale]}
                href={urlLocaleMap.power[defaultLocale]}
              >
                {xgettext('Power', 'Text for the link to Hudya Power.')}
              </MenuItem>
            )} */}
            {/* {defaultLocale === 'sv_SE' && (
              <Accordion
                as={MenuItem}
                hidden={activeProducts.power[defaultLocale]}
              >
                <Accordion.Title
                  active={this.state.activeIndex === 4}
                  index={4}
                  style={{ color: '#bbb' }}
                  onClick={this.handleAccorionClick}
                >
                  {xgettext('Power', 'Text for the link to Hudya Power.')}
                  <Icon name="dropdown" />
                </Accordion.Title>
                <Accordion.Content
                  active={this.state.activeIndex === 4}
                  style={{
                    backgroundColor: 'rgba(255, 255, 255, 0.1)',
                    borderRadius: '6px',
                    marginLeft: 10,
                    marginRight: 10,
                  }}
                >
                  <MenuItem
                    as="a"
                    href={urlLocaleMap.power[defaultLocale]}
                    disabled={!activeProducts.power[defaultLocale]}
                  >
                    {' '}
                    {gettext('Order')}
                  </MenuItem>
                  <MenuItem
                    as="a"
                    href="https://wp.hudya.se/sv/produkter/el/"
                    disabled={!activeProducts.power[defaultLocale]}
                  >
                    {' '}
                    {gettext('Prices')}
                  </MenuItem>
                </Accordion.Content>
              </Accordion>
            )} */}

            {activeProducts.refinancing[defaultLocale] && (
              <MenuItem as="a" href={'/refinansiering'}>
                {xgettext('Refinance', 'Text for the link to Hudya Refinance.')}
              </MenuItem>
            )}
            <MenuItem
              as="a"
              disabled={!activeProducts.insurance[defaultLocale]}
              href={'/forsikring'}
            >
              {xgettext('Insurance', 'Text for the link to Hudya Insurance.')}
            </MenuItem>
            {activeProducts.customerService[defaultLocale] && (
              <MenuItem
                as="a"
                href={`${wpSiteUrl}${urlLocaleMap.customerService[defaultLocale]}`}
              >
                {xgettext(
                  'Customer service',
                  'Text for the link to Hudya customer service.'
                )}
              </MenuItem>
            )}
            {activeProducts.blog[defaultLocale] && (
              <a
                className="item"
                href={`${wpSiteUrl}/${urlLocaleMap.blog[defaultLocale]}`}
              >
                {xgettext('Blog', 'Text for the link to the Hudya blog.')}
              </a>
            )}
            {!credentials.isAuthenticated && defaultLocale !== 'sv_SE' && (
              <MenuItem
                href="/login"
                style={{ paddingLeft: '1rem', paddingRight: '1rem' }}
              >
                <Button
                  fluid
                  size="huge"
                  style={{ backgroundColor: '#e65e00', color: 'white' }}
                >
                  {gettext('My Hudya')}
                </Button>
              </MenuItem>
            )}
            {credentials.isAuthenticated && (
              <Accordion>
                <Accordion.Title
                  fluid
                  primary
                  active={this.state.activeIndex === 1}
                  as={Button}
                  index={1}
                  size="huge"
                  style={{ color: 'white' }}
                  onClick={this.handleAccorionClick}
                >
                  <Icon name="user" />
                  {gettext('Account')}
                  <Icon name="dropdown" />
                </Accordion.Title>
                <Accordion.Content
                  active={this.state.activeIndex === 1}
                  style={{ backgroundColor: '#e65e00' }}
                >
                  <MenuItem
                    as="a"
                    name={gettext('overview')}
                    href="/"
                    style={{ color: 'white' }}
                  />
                  {/* <MenuItem
                    as="a"
                    name={gettext('settings')}
                    href="/account"
                    style={{ color: 'white' }}
                  /> */}

                  <MenuItem
                    name={gettext('Logout')}
                    onClick={this.handleLogoutItemClick}
                    style={{ color: 'white' }}
                  />
                </Accordion.Content>
              </Accordion>
            )}
            <div className="need-help">
              <p className="footer-title">
                {gettext('Need help? Get in touch!')}
              </p>
              <p>
                <strong>
                  <a href="tel:21415600">21 41 56 00</a>
                  <br />
                  <a href="mailto:support@hudya.no">support@hudya.no</a>
                </strong>
              </p>
            </div>
          </div>
        </Sidebar>

        <Pusher dimmed={this.props.navigation.pushMenu.isToggled}>
          <Grid
            stackable
            style={{ justifyContent: 'center' }}
            verticalAlign="middle"
          >
            <Grid.Row>
              <Grid.Column width="12">
                {credentials.isAuthenticated && !fullscreen && (
                  <WelcomeBanner
                    account={this.props.account}
                    email="support@hudya.no"
                    credentials={this.props.credentials}
                  />
                )}
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <div style={{ minHeight: '60vh' }}>{this.props.children}</div>

          {!fullscreen && (
            <Footer
              actions={this.props.actions}
              config={this.props.config}
              credentials={this.props.credentials}
            />
          )}
        </Pusher>
      </Pushable>
    )
  }
}

export default withRouter(
  connect(
    (state) => ({
      account: state.global.entities.account,
      config: state.global.config,
      credentials: state.global.credentials,
      navigation: state.global.gui.navigation,
    }),
    (dispatch) => ({
      actions: Object.assign(
        {},
        { dispatch },
        bindActionCreators({ setDefaultLocale }, dispatch)
      ),
    })
  )(PushMenuWrapper)
)
