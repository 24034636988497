import React from 'react'
import PropTypes from 'prop-types'

import { Grid, Container, Icon } from 'semantic-ui-react'
import styles from './feature.module.css'
const Features = (props, context) => {
  const { gettext, xgettext } = context
  return (
    <div className={styles['mobile-features']}>
      <Container>
        <div className="ui stackable doubling three column grid center aligned">
          <Grid.Column>
            <Icon name="sliders horizontal" size="big" />
            <h3 className={styles['feature-title']}>
              {gettext('Full overview on MinSide')}
            </h3>
            <p>
              {gettext(
                '      At MySide you can keep track of your consumption, Order and activate SIM card, and order / cancel services on your subscription'
              )}
            </p>
          </Grid.Column>
          <Grid.Column>
            <Icon name="clipboard list" size="big" />
            <h3 className={styles['feature-title']}>
              {gettext('Superb coverage')}
            </h3>
            <p>
              {gettext(
                'You get full Telenor coverage with 4G and crystal clear call quality with HDVoice'
              )}
            </p>
          </Grid.Column>
          <Grid.Column>
            <Icon name="smile outline" size="big" />
            <h3 className={styles['feature-title']}>
              {gettext('Super customer service')}
            </h3>
            <p>
              {gettext(
                'At the Telipol customer service center at Notodden, our team is ready to assist you. We aim to solve your question at first inquiry, in the easiest possible way.'
              )}
            </p>
          </Grid.Column>
        </div>
      </Container>
    </div>
  )
}

Features.contextTypes = {
  gettext: PropTypes.func.isRequired,
  xgettext: PropTypes.func.isRequired
}

export default Features
