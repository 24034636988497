export default [
  {
    name: 'Bil',
    url: 'https://hudya.no/nb/forsikring/bilforsikring/',
    locale: 'nb_NO'
  },
  {
    name: 'Hytte',
    url: 'https://hudya.no/nb/forsikring/hytteforsikring/'
  },
  {
    name: 'Hus',
    url: 'https://hudya.no/nb/forsikring/husforsikring/'
  },
  {
    name: 'Båt',
    url: 'https://hudya.no/nb/forsikring/batforsikring/'
  },
  {
    name: 'Reise',
    url: 'https://hudya.no/nb/forsikring/reiseforsikring/'
  },
  {
    name: 'Moped',
    url: 'https://hudya.no/nb/forsikring/mopedforsikring/'
  },
  {
    name: 'Bobil',
    url: 'https://hudya.no/nb/forsikring/bobilforsikring/'
  },
  {
    name: 'Campingvogn',
    url: 'https://hudya.no/nb/forsikring/campingvognforsikring/'
  },
  {
    name: 'Dødsfall',
    url: 'https://hudya.no/nb/forsikring/dodsfallsforsikring/'
  },
  {
    name: 'Barn',
    url: 'https://hudya.no/nb/forsikring/barneforsikring/'
  },
  {
    name: 'Fritidstraktor',
    url: 'https://hudya.no/nb/forsikring/fritidstraktorforsikring/'
  },
  {
    name: 'Hund',
    url: 'https://hudya.no/nb/forsikring/hundeforsikring/'
  },
  {
    name: 'Katt',
    url: 'https://hudya.no/nb/forsikring/katteforsikring/'
  },
  {
    name: 'Kritisk sykdom',
    url: 'https://hudya.no/nb/forsikring/kritisk-sykdom-forsikring/'
  },
  {
    name: 'Innbo',
    url: 'https://hudya.no/nb/forsikring/innboforsikring/'
  },
  {
    name: 'Snøscooter',
    url: 'https://hudya.no/nb/forsikring/snoscooterforsikring/'
  },
  {
    name: 'Tilhenger',
    url: 'https://hudya.no/nb/forsikring/tilhengerforsikring/'
  },
  {
    name: 'Uregistrert kjøretøy',
    url: 'https://hudya.no/nb/forsikring/forsikring-for-uregistrert-kjoretoy/'
  },
  {
    name: 'Veteranbil',
    url: 'https://hudya.no/nb/forsikring/veteranbilforsikring/'
  },
  {
    name: 'Motorsykkel',
    url: 'https://hudya.no/nb/forsikring/motorsykkelforsikring/'
  },
  {
    name: 'Uførhet',
    url: 'https://hudya.no/nb/forsikring/uforeforsikring/'
  },
  {
    name: 'Ulykke',
    url: 'https://hudya.no/nb/forsikring/ulykkesforsikring/'
  },
  {
    name: 'Verdigjenstand',
    url: 'https://hudya.no/nb/forsikring/verdigjenstandsforsikring/'
  }
]

// export default [
//   {
//     name: 'Hvordan får jeg en god elbilforsikring på Tesla og andre elbiler?',
//     url:
//       'https://hudya.no/forsikring/bilforsikring/hvordan-far-jeg-en-god-elbilforsikring-pa-tesla-og-andre-elbiler/'
//   },
//   {
//     name: 'Hva er egenandelen ved veihjelp?',
//     url:
//       'https://hudya.no/forsikring/bilforsikring/hva-er-egenandelen-ved-veihjelp/'
//   },
//   {
//     name: 'Hva er egenandelen ved skade på bilens glassruter?',
//     url:
//       'https://hudya.no/forsikring/bilforsikring/hva-er-egenandelen-ved-skade-pa-bilens-glassruter/'
//   },
//   {
//     name: 'Gjelder min Hudya bilforsikring ved øvelseskjøring?',
//     url:
//       'https://hudya.no/forsikring/bilforsikring/hva-er-egenandelen-ved-skade-pa-bilens-glassruter/'
//   },
//   {
//     name: 'Kan jeg overføre bonus til barna mine?',
//     url:
//       'https://hudya.no/forsikring/bilforsikring/kan-jeg-overfore-bonus-til-barna-mine/'
//   },
//   {
//     name: 'Tilbyr Hudya startbonus?',
//     url: 'https://hudya.no/forsikring/bilforsikring/tilbyr-hudya-startbonus/'
//   },
//   {
//     name: 'Hvem kan jeg overføre bonus til?',
//     url:
//       'https://hudya.no/forsikring/bilforsikring/hvem-kan-jeg-overfore-bonus-til/'
//   },
//   {
//     name: 'Hvem kan opptjene bonus på bilforsikring?',
//     url:
//       'https://hudya.no/forsikring/bilforsikring/hvem-kan-opptjene-bonus-pa-bilforsikring/'
//   },
//   {
//     name: 'Fører alle skader på bilen til bonustap?',
//     url:
//       'https://hudya.no/forsikring/bilforsikring/forer-alle-skader-pa-bilen-til-bonustap/'
//   },
//   {
//     name: 'Hvordan fungerer bonusordningen på bilforsikringen?',
//     url:
//       'https://hudya.no/forsikring/bilforsikring/hvordan-fungerer-bonusordningen-pa-bilforsikringen/'
//   },
//   {
//     name: 'Hvor ringer jeg om jeg trenger veihjelp i Norge eller i Europa?',
//     url:
//       'https://hudya.no/forsikring/bilforsikring/hvor-ringer-jeg-om-jeg-trenger-veihjelp-i-norge-eller-i-europa/'
//   },
//   {
//     name: 'Jeg har fått glass-/steinsprutskade på bilen – hvor får jeg hjelp?',
//     url:
//       'https://hudya.no/forsikring/bilforsikring/jeg-har-fatt-glass-steinsprutskade-pa-bilen-hvor-far-jeg-hjelp/'
//   }
// ]
