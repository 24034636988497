import React from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Button, Card, Grid } from 'semantic-ui-react'
import style from '../../home.module.css'

class ProductCard extends React.Component {
  static contextTypes = {
    xgettext: PropTypes.func.isRequired,
    gettext: PropTypes.func.isRequired
  }

  render() {
    return (
      <Card
        fluid
        className={[
          style['product-card'],
          this.props.disabled ? style['product-card-disabled'] : ''
        ].join(' ')}
      >
        {this.props.campaign && (
          <div className="ribbon-wrapper-green">
            <div className="ribbon-green">
              <a
                href={this.props.loginUri}
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: 'white' }}
              >
                Kampanje
              </a>
            </div>
          </div>
        )}
        <div className="content" style={{ minHeight: 415 }}>
          <h2 className={style['product-card-title']}>{this.props.header}</h2>
          <Grid>
            <Grid.Row
              style={{
                marginBottom: '2em',
                display: 'flex',
                justifyContent: 'center'
              }}
            >
              <Grid.Column width="16">
                <p className={style['product-card-info']}>{this.props.desc}</p>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          {!this.props.disabled && (
            <span>
              <small>{this.props.priceTopText}</small>
              <p className={style['product-card-price']}>{this.props.price}</p>
              <p className={style['product-card-price-detail']}>
                <small>{this.props.priceBottomText}</small>
              </p>
            </span>
          )}
          <Button
            as="a"
            disabled={this.props.disabled}
            href={this.props.orderButtonUri}
            size="big"
            style={{
              color: '#ffffff',
              fontWeight: 600,
              borderRadius: 30,
              marginBottom: 15,
              background: 'linear-gradient(225deg, #fb637e 0%, #ff6900 100%)'
            }}
          >
            {this.props.orderButtonText}
          </Button>

          <div style={{ minHeight: 23 }}>
            <a
              hidden={this.props.disabled}
              href={this.props.readMoreUri}
              className={style['link-to-product']}
            >
              {this.props.readMore}
            </a>
          </div>
        </div>
      </Card>
    )
  }
}
export default withRouter(
  connect(
    state => ({
      config: state.global.config
    }),
    dispatch => ({
      actions: Object.assign({}, { dispatch }, bindActionCreators({}, dispatch))
    })
  )(ProductCard)
)
ProductCard.propTypes = {
  productName: PropTypes.string,
  salesPitch: PropTypes.string
}
ProductCard.defaultProps = {
  productName: 'Fantastic product',
  salesPitch: 'All your base are belong to us',
  imgSrc: 'https://hudya.no/wp-content/uploads/2017/01/product-mobile-1@2x.png'
}
