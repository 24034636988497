import React from 'react'
import ReactPixel from 'react-facebook-pixel'

import PropTypes from 'prop-types'
import jwtDecode from 'jwt-decode'
import { Form, Button, Modal, Header } from 'semantic-ui-react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { CSSTransition } from 'react-transition-group'

import { BobApi } from '../../store/webapi/bobBrain'
import { setCredentials } from '../../store/global/credentials'
import { resetBob } from '../../store/global/bob'
import { legalEntityAPI } from '../../store/webapi/legalEntity'

import Utterance from '../Chat/components/Utterance'
import ReactMarkdown from 'react-markdown/with-html'
import config from '../../config'
import Chat from '../Chat'
import store from '../../store/configureStore'

//import InputElements from './components/InputElements'

class ChatWidget extends React.Component {
  static contextTypes = {
    xgettext: PropTypes.func.isRequired,
    gettext: PropTypes.func.isRequired,
  }

  constructor(props, context) {
    super(props)
    this.state = {
      httpError: false,
      fuzzyValue: '',
      renderedThings: [],
      itemsRendered: 0,
      inputInfocus: false,
    }

    this.emailInput = React.createRef()
    this.handleFormSubmit = this.handleFormSubmit.bind(this)
    this.handleFieldChange = this.handleFieldChange.bind(this)
    this.updateRenderedThings = this.updateRenderedThings.bind(this)
    this.handleButtonLogin = this.handleButtonLogin.bind(this)
    this.inputFocusToggle = this.inputFocusToggle.bind(this)
  }
  logUserIn(jwt) {
    if (!jwt) {
      return
    }
    this.props.actions.setCredentials(jwt)

    const decodedJWT = jwtDecode(jwt)

    this.props.actions.dispatch(
      legalEntityAPI.actions.entity.sync({ id: decodedJWT.sub })
    )
  }
  handleButtonLogin() {
    clearTimeout(this.timer)

    const jwt = localStorage.getItem('brainSessionJwt')
    this.props.actions.setCredentials(jwt)

    const decodedJWT = jwtDecode(jwt)

    this.props.actions.dispatch(
      legalEntityAPI.actions.entity.sync({ id: decodedJWT.sub })
    )
  }
  handleFormSubmit(value) {
    if (
      this.state.fuzzyValue === '' ||
      typeof this.state.fuzzyValue === 'undefined'
    ) {
      return
    }
    const answer = {
      content_type: 'text',
      utterance: this.state.fuzzyValue,
      suggested_responses: [],
      output_contexts: [],
      next: null,
      input_type: 'text',
      force_suggested_responses: false,
      extra_parameters: {},
      attachment: [],
      insert_delay: 1000,
      userResponse: true,
    }
    const updatedState = {
      renderedThings: this.state.renderedThings.concat(answer),
    }
    this.setState(updatedState)
    this.props.actions.dispatch(
      BobApi.actions.talkToBob.post('', {
        vertex: {
          id: this.props.bob.brainSessionId,
          ga_client_id: this.props.bob.gaClientId,
        },

        content_type: 'utterance',
        content: {
          language_code: this.props.config.defaultLocale.replace(/_/g, '-'),
          utterance: this.state.fuzzyValue,
          utterance_type: 'text',
        },
      })
    )
    this.setState({ fuzzyValue: '' })
  }
  handleFieldChange(event, data) {
    this.setState({ fuzzyValue: data.value })
  }
  inputFocusToggle(event, data) {
    this.setState({ inputInfocus: !this.state.inputInfocus })
  }

  scheduleNextUpdate(time) {
    this.timer = setInterval(this.updateRenderedThings, time)
  }

  updateRenderedThings() {
    const itemsRendered = this.state.itemsRendered
    const itemToRender = this.props.data[this.state.itemsRendered]
    const jwt = localStorage.getItem('brainSessionJwt')

    if (itemsRendered < this.props.data.length) {
      const stepParams = itemToRender.parameters

      if (stepParams.fb_event) {
        ReactPixel.trackCustom(stepParams.fb_event, {})
      }
      if (itemToRender.input_type === 'redirect') {
        this.logUserIn(jwt)
      }

      const updatedState = {
        renderedThings: this.state.renderedThings.concat(
          this.props.data[this.state.itemsRendered]
        ),
        itemsRendered: itemsRendered + 1,
      }
      this.setState(updatedState)
    }
  }

  componentDidMount() {
    console.log('did mount')

    ReactPixel.init(config.FB_PIXEL_ID, { uid: this.props.bob.userId })
    this.updateRenderedThings()
    this.scheduleNextUpdate(1000)
  }

  render() {
    const { gettext, xgettext } = this.context

    const loading = this.state.renderedThings.length === 0

    return (
      <div>
        <Form
          error={this.state.error}
          onSubmit={() => this.handleFormSubmit(this.state.fuzzyValue)}
        >
          <div className="chat">
            <div className="container" hidden={!loading}>
              <div className="base-square" />
              <div className="bubble" />
              <div className="loading">
                <div className="dot dot--one" />
                <div className="dot dot--two" />
                <div className="dot dot--three" />
              </div>
              <br />
            </div>

            {this.state.renderedThings.map((field, index) => (
              <Utterance
                utterance={field.utterance}
                userResponse={field.userResponse}
                key={index}
              />
            ))}

            {this.state.renderedThings.map((field, index) => (
              <CSSTransition
                appear
                in={true}
                className="chat-input-group"
                classNames="chat-input"
                timeout={{
                  enter: 2000,
                  exit: 2000,
                }}
                unmountOnExit
                key={index}
              >
                <div style={{ marginBottom: 10 }}>
                  {field.suggestedResponses.map((button, index) => (
                    <Modal
                      trigger={
                        <button
                          className="ui circular secondary button large"
                          style={{ fontSize: 16, marginBottom: 5 }}
                        >
                          {button.title}
                        </button>
                      }
                      basic
                      size="tiny"
                      closeIcon
                      key={index}
                      // defaultOpen
                    >
                      <Header icon="chat" content="Snakk med oss!" />
                      <Modal.Content style={{ minHeight: 1500 }}>
                        <p>
                          Her kan du lære mer om våre produkter og bestille våre
                          tjenester
                        </p>
                        <div style={{ minHeight: 900 }}>
                          <Chat
                            goalFlow={button.flow}
                            step={button.step}
                            store={store}
                          />
                        </div>
                      </Modal.Content>
                    </Modal>
                  ))}
                </div>
              </CSSTransition>
            ))}
          </div>
        </Form>
      </div>
    )
  }
}
ChatWidget.propTypes = { bobEvent: PropTypes.string }
ChatWidget.defaultProps = { bobEvent: 'HUDYA_WELCOME_MESSAGE' }
export default withRouter(
  connect(
    (state) => ({
      account: state.global.entities.account,
      bob: state.global.bob,
      config: state.global.config,
      credentials: state.global.credentials,
      webapi: state.webapi,
    }),
    (dispatch) => ({
      actions: Object.assign(
        {},
        { dispatch },
        bindActionCreators({ setCredentials, resetBob }, dispatch)
      ),
    })
  )(ChatWidget)
)
