import React from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Container, Grid } from 'semantic-ui-react'
import styles from './power.module.css'
import ReactMarkdown from 'react-markdown/with-html'
import faq from './faq'

import ProductLinks from '../components/ProductLinks'
import ChatContainer from '../../../components/ChatContainer'

class PublicPower extends React.PureComponent {
  static contextTypes = {
    gettext: PropTypes.func.isRequired,
    xgettext: PropTypes.func.isRequired,
  }

  static propTypes = {}

  constructor(props, context) {
    super(props, context)
    document.title = context.xgettext('Strøm - Hudya', 'power page title')
  }

  render() {
    const params = new URLSearchParams(window.location.search)
    const { gettext, xgettext } = this.context

    const flow = params.get('flow')
    const step = params.get('s')
    const { defaultLocale, activeProducts, urlLocaleMap } = this.props.config
    let goalflow = ''
    if (defaultLocale == 'sv_SE') {
      goalflow = 'se_power_onboarding'
    }
    if (defaultLocale == 'nb_NO') {
      goalflow = 'power_onboarding'
    }
    if (defaultLocale == 'da_DK') {
      goalflow = 'dk_power'
    }

    return (
      <div className={styles['public-power']}>
        <ChatContainer
          flow={flow || goalflow}
          step={step || 'init_with_fixed_start'}
          chatH1={xgettext('Hudya Power', 'power landing page')}
          chatH2={gettext('Power sales heading')}
          chatSideBody={gettext('Power sales text')}
        />

        <ProductLinks />
        <div className={styles['power-faq']}>
          <Container>
            <div className={styles['svg-curve']}>
              <svg
                version="1.1"
                fill="#FFFFF7"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                viewBox="0 0 1080 65"
                preserveAspectRatio="none"
              >
                <path d="M0,45.069C671.161,45.478,1080,0,1080,0v65H0V45.069z" />
              </svg>
            </div>
            <Grid stackable>
              <Grid.Row textAlign="left">
                <Grid.Column width="7">
                  <h3 className={styles['section-title']}>
                    <ReactMarkdown
                      source={xgettext(
                        'All you need to know about <strong>power</strong>',
                        'mobile faq header'
                      )}
                      escapeHtml={false}
                    />
                  </h3>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row textAlign="left">
                {faq.map((f) => {
                  if (f.locale == defaultLocale) {
                    return <Faq faq={f} />
                  }
                })}
              </Grid.Row>
            </Grid>
          </Container>
        </div>
      </div>
    )
  }
}

export default connect(
  (state) => ({
    login: state.core.auth.login,
    credentials: state.global.credentials,
    config: state.global.config,
  }),
  (dispatch) => ({
    actions: bindActionCreators({}, dispatch),
  })
)(PublicPower)

function Faq(props) {
  return (
    <Grid.Column width="8">
      <a href={props.faq.url}>{props.faq.name}</a>
    </Grid.Column>
  )
}
