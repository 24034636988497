import React, { useEffect, useState } from 'react';
import { Container } from 'semantic-ui-react';

import useWPService from '../../services/wpService/useWPService';
import PageLoader from '../../components/PageLoader/pageLoader';
import HtmlParse from '../../components/HtmlParser/htmlParser';

import styles from './styles.module.scss';

const CustomerService = () => {
  const wpService = useWPService();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});
  useEffect(() => {
    const fetchData = async () => {
      const result = await wpService.getPage('customerService');
      if (result.status) {
        setData(result.response);
      }
      setLoading(false);
    };

    fetchData();
  }, []);
  return loading ? (
    <div className={styles.container}>
      <PageLoader fill loading />
    </div>
  ) : (
    <div className={styles.customerService}>
      <Container className={styles.container}>
        <div
          className="ui grid stackable mobile reversed"
          style={{ marginBottom: 40 }}
        >
          <div className="eight wide column centered" style={{ padding: 40 }}>
            <img
              className="ui spaced fluid image phone"
              style={{ width: '100%' }}
              src="assets/customerService/tel.png"
              alt="phone"
            />
          </div>
          <div className="eight wide column" style={{ color: '#fff' }}>
            <img src="https://pallauvrak.ghost.io/content/images/2020/08/tel-1.svg" />
            <h1 className={styles.bigTitle}>
              {HtmlParse({ data: data.custom_page_title || null })}
            </h1>
            <h2 className={styles.title}>
              {HtmlParse({ data: data.opening_hours_title || null })} <br />
              <strong>{HtmlParse({ data: data.opening_hours || null })}</strong>
            </h2>
            <h2 className={styles.title}>
              {HtmlParse({ data: data.phone_title || null })} <br />
              <strong>
                <a href={`tel:${data.phone}`} style={{ color: '#fff' }}>
                  {HtmlParse({ data: data.phone || null })}
                </a>
              </strong>
            </h2>
            <h2 className={styles.title}>
              {HtmlParse({ data: data.email_title || null })} <br />
              <strong>
                <a
                  href={`mailto:${data.email_address}`}
                  style={{ color: '#fff' }}
                >
                  {HtmlParse({ data: data.email_address || null })}
                </a>
              </strong>
            </h2>
            <h2 className={styles.title}>
              {HtmlParse({ data: data.insurance_title || null })} <br />
              {HtmlParse({ data: data.email_title || null })}
              <strong>
                <a
                  href={`mailto:${data.insurance_email_address}`}
                  style={{ color: '#fff', paddingLeft: 10 }}
                >
                  {data.insurance_email_address}
                </a>
              </strong>
            </h2>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default CustomerService;
