import Account from './scenes/Account';
import KitchenSink from './components/KitchenSink';
import Power from './scenes/Products/Power';
import PublicPower from './scenes/LandingPages/Power';
import Signup from './scenes/Signup';
import Consent from './scenes/Consent';
import Overview from './scenes/Overview';
import SubscriptionDetails from './scenes/Subscriptions/SubscriptionDetails';
// import Subscriptions from './scenes/Subscriptions';
import Welcome from './scenes/Welcome';
import Aconto from './scenes/LandingPages/Aconto';
import MobileLandingPage from './scenes/LandingPages/Mobile';
import MobileOverview from './scenes/LandingPages/Mobile/Overview';
import Intro from './scenes/LandingPages/Intro';
import PublicInsurance from './scenes/LandingPages/Insurance';
import Home from './scenes/LandingPages/Home';
import mobileProduct from './scenes/LandingPages/Mobile/mobileProduct';
import MobilePrices from './scenes/LandingPages/Mobile/producPrices';
import Article from './scenes/LandingPages/Article';
import OverviewDetails from './scenes/Overview/OverviewDetails/OverviewDetails';
import CustomerService from './scenes/CustomerService/customerService';

const createRoutes = (store) => [
  {
    component: Article,
    exact: true,
    path:
      '/mobilabonnement/hudya-lanserer-hudya-barn-1gb-til-kun-kr-99-per-mnd-i-telenor-nettet/',
    protected: false,
  },
  {
    component: Power,
    exact: true,
    path: '/products/power',
    protected: true,
  },
  {
    component: CustomerService,
    exact: true,
    path: '/customer-service',
    protected: false,
  },
  {
    component: Aconto,
    exact: true,
    path: '/refinansiering',
    protected: false,
  },
  {
    component: Account,
    exact: true,
    path: '/account',
    protected: true,
  },
  {
    component: Consent,
    exact: true,
    path: '/consent',
    protected: true,
  },
  {
    component: Intro,
    exact: true,
    path: '/intro',
    protected: false,
  },
  {
    component: Home,
    exact: true,
    path: '/',
    protected: false,
  },
  {
    component: Home,
    exact: true,
    path: '/forside',
    protected: false,
  },
  {
    component: Overview,
    exact: true,
    path: '/products',
    protected: true,
    routes: [],
  },
  {
    component: OverviewDetails,
    exact: true,
    path: '/products/details/:type',
    protected: true,
    routes: [],
  },
  {
    component: SubscriptionDetails,
    exact: true,
    path: '/products/:type/:provider/:id',
    protected: true,
  },
  {
    component: PublicInsurance,
    exact: true,
    path: '/forsikring',
    protected: false,
  },

  {
    component: Home,
    exact: true,
    path: '/login:key?',
  },
  {
    component: KitchenSink,
    exact: true,
    path: '/kitchensink',
  },

  // {
  //   component: PublicPower,
  //   exact: true,
  //   path: store.getState().global.config.urlLocaleMap.power[
  //     store.getState().global.config.defaultLocale
  //   ]
  // },
  {
    component: PublicPower,
    exact: true,
    path: '/(el|strom)/',
  },

  {
    component: MobileOverview,
    exact: true,
    fullscreen: true,
    path: '/(mobil|mobilabonnement)/(overview|produkter)',
  },
  {
    component: mobileProduct,
    exact: true,
    fullscreen: true,
    path:
      '/(mobil|mobilabonnement)/(flex|0mb|300mb|500mb|barn-1gb|1gb|2gb|3gb|5gb|6gb|10gb|12gb|20gb|30gb|mobilt-bredband)',
  },
  {
    component: MobilePrices,
    exact: true,
    fullscreen: true,
    path: '/(mobil|mobilabonnement)/ovrige-priser-alle-abonnement',
  },
  {
    component: MobileLandingPage,
    exact: true,
    fullscreen: true,
    path: '/(mobil|mobilabonnement)/',
  },

  {
    component: Overview,
    exact: true,
    fullscreen: true,
    path: '/telipol/',
    protected: true,
  },
  {
    component: Overview,
    exact: true,
    fullscreen: true,
    path: '/telibob/:phone?',
  },
  {
    component: Overview,
    exact: true,
    fullscreen: true,
    path: '/telipol/:phone?',
  },

  {
    component: Signup,
    exact: true,
    fullscreen: true,
    path: '/signup/:id?',
  },
  {
    component: Welcome,
    exact: true,
    fullscreen: false,
    path: '/welcome',
  },
];

export default createRoutes;
