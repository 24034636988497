import React from 'react'
import PropTypes from 'prop-types'
import renderHTML from 'react-render-html'

import { fm as _ } from '../../utils/string'
import config from '../../config'

export default class Footer extends React.Component {
  static contextTypes = {
    gettext: PropTypes.func.isRequired,
    xgettext: PropTypes.func.isRequired,
  }

  static propTypes = {
    actions: PropTypes.shape({ setDefaultLocale: PropTypes.func.isRequired })
      .isRequired,
    config: PropTypes.shape({
      defaultLocale: PropTypes.string.isRequired,
      activeProducts: PropTypes.object.isRequired,
    }).isRequired,
    credentials: PropTypes.object.isRequired,
  }

  constructor(props, context) {
    super(props, context)
    this.handleSelectLanguage = this.handleSelectLanguage.bind(this)
  }

  handleSelectLanguage(event, data) {
    const { setDefaultLocale } = this.props.actions

    setDefaultLocale(data.value)
  }

  render() {
    const { gettext, xgettext } = this.context
    const { defaultLocale, activeProducts, urlLocaleMap } = this.props.config
    const wpSiteUrl = config.WP_SITE_URL[defaultLocale]

    return (
      <div>
        {/* <div className="ui vertical segment inverted center aligned" /> */}
        <div className="ui vertical footer segment">
          <div
            className="svg-container"
            style={{
              height: '30px',
              marginTop: '-25px',
              position: 'absolute',
              left: 0,
              right: 0,
              zIndex: 1,
            }}
          >
            <svg
              fill="#FFFFF7"
              preserveAspectRatio="none"
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                height: '100%',
                width: '100%',
              }}
              version="1.1"
              viewBox="0 0 1080 22"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
            >
              <path d="M0,22h1080V8.067c0,0-539.279-19.53-1080,2.186V22z" />
            </svg>
          </div>
          <div className="color-bg">
            <div className="ui container">
              <div className="ui doubling stackable grid">
                <div className="row">
                  <div className="column">
                    <div className="ui segment center aligned">
                      <img
                        alt="hudya logo"
                        className="ui image small spaced"
                        src="https://aconto.no/Content/Images/logo.svg"
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="three wide column">
                    <h3 className="ui header">{gettext('Pages')}</h3>
                    <div className="ui link list" role="list">
                      <a className="item" href="/" role="listitem">
                        {xgettext(
                          'Home',
                          'Text for the link to the main Hudya page.'
                        )}
                      </a>
                      {activeProducts.corporate[defaultLocale] && (
                        <a
                          className="item"
                          href={`${wpSiteUrl}/bedrift`}
                          role="listitem"
                        >
                          {xgettext(
                            'Corporate',
                            'Text for the link to Hudya corporate page.'
                          )}
                        </a>
                      )}
                      {activeProducts.customerService[defaultLocale] && (
                        <a
                          className="item"
                          href={`${wpSiteUrl}${urlLocaleMap.customerService[defaultLocale]}`}
                          role="listitem"
                        >
                          {xgettext(
                            'Customer service',
                            'Text for the link to Hudya customer service.'
                          )}
                        </a>
                      )}
                      {activeProducts.blog[defaultLocale] && (
                        <a
                          className="item"
                          href={`${wpSiteUrl}${urlLocaleMap.blog[defaultLocale]}`}
                          role="listitem"
                        >
                          {xgettext(
                            'Blog',
                            'Text for the link to the Hudya blog.'
                          )}
                        </a>
                      )}
                    </div>
                  </div>
                  <div className="three wide column">
                    <h3 className="ui header">{gettext('Products')}</h3>
                    <div className="ui link list" role="list">
                      <a
                        style={{
                          pointerEvents: activeProducts.mobile[defaultLocale]
                            ? 'auto'
                            : 'none',
                          opacity: activeProducts.mobile[defaultLocale]
                            ? 1
                            : 0.5,
                        }}
                        className="item"
                        href={
                          this.props.credentials.isAuthenticated
                            ? '/products/mobile'
                            : '/mobilabonnement'
                        }
                        role="listitem"
                      >
                        {xgettext(
                          'Mobile',
                          'Text for the link to Hudya Mobile.'
                        )}
                      </a>

                      <a
                        className="item"
                        href={
                          this.props.credentials.isAuthenticated
                            ? '/products/insurance'
                            : '/forsikring'
                        }
                        role="listitem"
                        style={{
                          pointerEvents: activeProducts.insurance[defaultLocale]
                            ? 'auto'
                            : 'none',
                          opacity: activeProducts.insurance[defaultLocale]
                            ? 1
                            : 0.5,
                        }}
                      >
                        {xgettext(
                          'Insurance',
                          'Text for the link to Hudya Insurance.'
                        )}
                      </a>
                      {/* <a
                        className="item"
                        href={
                          this.props.credentials.isAuthenticated
                            ? '/products/power'
                            : urlLocaleMap.power[defaultLocale]
                        }
                        role="listitem"
                        style={{
                          pointerEvents: activeProducts.power[defaultLocale]
                            ? 'auto'
                            : 'none',
                          opacity: activeProducts.power[defaultLocale]
                            ? 1
                            : 0.5,
                        }}
                      >
                        {xgettext('Power', 'Text for the link to Hudya Power.')}
                      </a> */}
                      <a
                        className="item"
                        href={
                          this.props.credentials.isAuthenticated
                            ? '/refinansiering'
                            : '/refinansiering'
                        }
                        role="listitem"
                        style={{
                          pointerEvents: activeProducts.refinancing[
                            defaultLocale
                          ]
                            ? 'auto'
                            : 'none',
                          opacity: activeProducts.refinancing[defaultLocale]
                            ? 1
                            : 0.5,
                        }}
                      >
                        {xgettext(
                          'Refinance',
                          'Text for the link to Hudya Refinance.'
                        )}
                      </a>
                    </div>
                  </div>
                  <div className="four wide column center aligned">
                    <h3 className="ui header">
                      {gettext('Need help? Get in touch!')}
                    </h3>
                    <div className="ui list" role="list">
                      <a
                        className="item"
                        href={'tel:' + gettext('004721415600')}
                        role="listitem"
                      >
                        <strong>{gettext('21 41 56 00')}</strong>
                      </a>
                      <p style={{ fontSize: 12 }}>
                        {renderHTML(
                          gettext(
                            'Åpent på hverdager fra <br /> <strong>08.00 - 16.00</strong>'
                          )
                        )}
                      </p>
                      <a
                        className="item"
                        href={gettext('mailto:kundeservice@hudya.no')}
                        role="listitem"
                      >
                        <strong>{gettext('kundeservice@hudya.no')}</strong>
                      </a>
                    </div>
                  </div>
                  <div className="three wide column" />
                  <div className="three wide column">
                    <div className="ui grid">
                      {/* <div className="row">
                        <div className="column center aligned">
                          <Dropdown
                            compact
                            selection
                            options={selectLanguageOptions}
                            onChange={this.handleSelectLanguage}
                            value={selectLanguageDefaultValue.value}
                          />
                        </div>
                      </div> */}

                      <div className="row">
                        <div className="column center aligned">
                          <a
                            className="ui button icon circular large basic secondary"
                            href={gettext('https://www.facebook.com/Hudya.no/')}
                          >
                            <i className="facebook icon" />
                          </a>
                          <a
                            className="ui button icon circular large basic secondary"
                            href="https://www.linkedin.com/company/hudya-group-as"
                          >
                            <i className="linkedin icon" />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="column">
                    <div className="ui segment center aligned">
                      <p className="copyright">
                        {renderHTML(
                          _(
                            xgettext(
                              'Hudya Group AS - Company No {organization} - {address} - All rights reserved. <a href={userTermsLink}>User Terms</a> - <a href={privacyPolicyLink}>Privacy Policy</a> - <a href={cookiesLink}>Cookies</a>',
                              'Contact information displayed in the footer.'
                            ),
                            {
                              address: xgettext(
                                'Henrik Ibsens gate 100, 0255 Oslo',
                                'footer address'
                              ),
                              cookiesLink: `${wpSiteUrl}/cookies/`,
                              organization: gettext('916 845 782'),
                              privacyPolicyLink: `${wpSiteUrl}/personvernerklaering/`,
                              userTermsLink: `${wpSiteUrl}/brukervilkar/`,
                            }
                          )
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
