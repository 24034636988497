import React from 'react'
const SvgDivider = props => (
  <div
    className="svg-container"
    style={{
      height: '30px',
      marginTop: '-25px',
      position: 'absolute',
      left: 0,
      right: 0,
      zIndex: 1
    }}
  >
    <svg
      fill="#3F2A56"
      preserveAspectRatio="none"
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        height: '100%',
        width: '100%'
      }}
      version="1.1"
      viewBox="0 0 1080 22"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <path d={props.path} />
    </svg>
  </div>
)
export default SvgDivider
