import React from 'react'
import ReactPixel from 'react-facebook-pixel'

import PropTypes from 'prop-types'
import jwtDecode from 'jwt-decode'
import { Form } from 'semantic-ui-react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { BobApi } from '../../store/webapi/bobBrain'
import { setCredentials } from '../../store/global/credentials'
import { resetBob, alertNewMessage, initSoccet } from '../../store/global/bob'
import { legalEntityAPI } from '../../store/webapi/legalEntity'

import Utterance from './components/Utterance'
import ReactMarkdown from 'react-markdown/with-html'
import config from '../../config'
import InputElements from './components/InputElements'

class Chat extends React.Component {
  static contextTypes = {
    xgettext: PropTypes.func.isRequired,
    gettext: PropTypes.func.isRequired,
  }

  constructor(props, context) {
    super(props)
    this.state = {
      httpError: false,
      fuzzyValue: '',
      renderedThings: [],
      itemsRendered: 0,
      inputInfocus: false,
    }

    this.emailInput = React.createRef()
    this.handleFormSubmit = this.handleFormSubmit.bind(this)
    this.handleFieldChange = this.handleFieldChange.bind(this)
    this.updateRenderedThings = this.updateRenderedThings.bind(this)
    this.handleButtonAlternative = this.handleButtonAlternative.bind(this)
    this.handleButtonLogin = this.handleButtonLogin.bind(this)
    this.inputFocusToggle = this.inputFocusToggle.bind(this)
  }
  logUserIn(jwt) {
    if (!jwt) {
      return
    }
    this.props.actions.setCredentials(jwt)

    const decodedJWT = jwtDecode(jwt)

    this.props.actions.dispatch(
      legalEntityAPI.actions.entity.sync({ id: decodedJWT.sub })
    )
  }
  handleButtonLogin() {
    clearTimeout(this.timer)

    const jwt = localStorage.getItem('brainSessionJwt')
    this.props.actions.setCredentials(jwt)

    const decodedJWT = jwtDecode(jwt)

    this.props.actions.dispatch(
      legalEntityAPI.actions.entity.sync({ id: decodedJWT.sub })
    )
  }
  handleFormSubmit(value) {
    if (
      this.state.fuzzyValue === '' ||
      typeof this.state.fuzzyValue === 'undefined'
    ) {
      return
    }
    const answer = {
      content_type: 'text',
      utterance: this.state.fuzzyValue,
      suggested_responses: [],
      output_contexts: [],
      next: null,
      input_type: 'text',
      force_suggested_responses: false,
      extra_parameters: {},
      attachment: [],
      insert_delay: 1000,
      userResponse: true,
    }
    const updatedState = {
      renderedThings: this.state.renderedThings.concat(answer),
    }
    this.setState(updatedState)
    this.props.actions.dispatch(
      BobApi.actions.talkToBob.post('', {
        vertex: {
          id: this.props.bob.brainSessionId,
          ga_client_id: this.props.bob.gaClientId,
        },

        content_type: 'utterance',
        content: {
          language_code: this.props.config.defaultLocale.replace(/_/g, '-'),
          utterance: this.state.fuzzyValue,
          utterance_type: 'text',
        },
      })
    )
    this.setState({ fuzzyValue: '' })
  }
  handleFieldChange(event, data) {
    this.setState({ fuzzyValue: data.value })
  }
  inputFocusToggle(event, data) {
    this.setState({ inputInfocus: !this.state.inputInfocus })
  }

  scheduleNextUpdate(time) {
    this.timer = setInterval(this.updateRenderedThings, time)
  }

  updateRenderedThings() {
    const itemsRendered = this.state.itemsRendered
    const itemToRender =
      this.props.bob.chatElements[this.state.itemsRendered] || {}
    const jwt = localStorage.getItem('brainSessionJwt')

    if (itemToRender['content_type'] === 'forced-payload') {
      this.props.bob.chatElements.pop()
      this.props.actions.dispatch(
        BobApi.actions.talkToBob.post('', {
          vertex: {
            id: this.props.bob.brainSessionId,
            ga_client_id: this.props.bob.gaClientId,
          },

          content_type: 'utterance',
          content: {
            language_code: this.props.config.defaultLocale.replace(/_/g, '-'),
            utterance: '',
            payload: itemToRender.payload,
            utterance_type: 'text',
          },
        })
      )
      return
    }
    if (itemsRendered < this.props.bob.chatElements.length) {
      const stepParams = itemToRender.parameters || {}

      if (stepParams.fb_event) {
        ReactPixel.trackCustom(stepParams.fb_event, {})
      }
      if (itemToRender.input_type === 'redirect') {
        this.logUserIn(jwt)
      }

      const updatedState = {
        renderedThings: this.state.renderedThings.concat(
          this.props.bob.chatElements[this.state.itemsRendered]
        ),
        itemsRendered: itemsRendered + 1,
      }

      this.setState(updatedState)
    }
  }

  handleButtonAlternative(target, source) {
    const answer = {
      content_type: 'text',
      utterance: source.utterance,
      suggested_responses: [],
      output_contexts: [],
      next: null,
      input_type: 'text',
      force_suggested_responses: false,
      extra_parameters: {},
      attachment: [],
      insert_delay: 1000,
      userResponse: true,
    }
    const updatedState = {
      renderedThings: this.state.renderedThings.concat(answer),
    }

    this.setState(updatedState)

    this.props.actions.dispatch(
      BobApi.actions.talkToBob.post('', {
        vertex: {
          id: this.props.bob.brainSessionId,
          ga_client_id: this.props.bob.gaClientId,
        },

        content_type: 'utterance',
        content: {
          language_code: this.props.config.defaultLocale.replace(/_/g, '-'),
          utterance: source.utterance,
          payload: source.payload,
          utterance_type: 'text',
        },
      })
    )
  }

  componentDidMount() {
    // this.props.actions.dispatch(connect('https://ws.hudya.io/'))
    this.props.actions.initSoccet()
    this.props.actions.alertNewMessage()
    ReactPixel.init(config.FB_PIXEL_ID, { uid: this.props.bob.userId })
    this.updateRenderedThings()
    this.scheduleNextUpdate(1000)
    if (
      !this.props.webapi.bob.talkToBob.loading &&
      this.props.bobEvent !== ''
    ) {
      this.props.actions.dispatch(
        BobApi.actions.talkToBob.post('', {
          vertex: {
            id: this.props.bob.brainSessionId,
            ga_client_id: this.props.bob.gaClientId,
            client_knowledge: this.props.clientKnowledge,
          },

          content_type: 'goal',
          content: {
            language_code: this.props.config.defaultLocale.replace(/_/g, '-'),
            action: 'activate',
            goal_flow: this.props.goalFlow || 'power_onboarding',
            step: this.props.step || '',
          },

          contexts: [],
        })
      )
    }
  }
  componentDidUpdate(prevProps) {
    if (!this.props.webapi.bob.talkToBob.loading) {
      if (this.props.bobEvent !== prevProps.bobEvent) {
        this.props.actions.resetBob()

        this.props.actions.dispatch(
          BobApi.actions.talkToBob.post('', {
            vertex: {
              id: this.props.bob.brainSessionId.id,
              ga_client_id: this.props.bob.gaClientId,
              client_knowledge: this.props.clientKnowledge,
            },

            content_type: 'goal',
            content: {
              language_code: this.props.config.defaultLocale.replace(/_/g, '-'),
              action: 'activate',
              goal_flow: this.props.goalFlow || 'power_onboarding',
            },

            contexts: [],
          })
        )
      }
    }
  }
  componentWillUnmount() {
    this.props.actions.resetBob()

    clearTimeout(this.timer)
  }

  render() {
    const { gettext, xgettext } = this.context
    const dialogStartMessages = {
      hudya_intro: [
        xgettext(
          'I snitt sparer kunder over <strong>10 000 kroner årlig</strong> på å samle tjenestene hos oss',
          'hudya_intro'
        ),
      ],
      aconto_refinancing: [
        gettext(
          'Hei, i snitt sparer våre kunder over 50.000 kr på å refinansiere gjennom oss'
        ),
      ],
      power_onboarding: [
        xgettext(
          'I snitt sparer kunder over <strong>10 000 kroner årlig</strong> på å samle tjenestene hos oss',
          'power_onboarding'
        ),
      ],
      insurance_leads: [
        xgettext(
          'Vi har et solid skadeoppgjør og landets mest fornøyde og lojale kunder',
          'insurance_leads'
        ),
      ],
      mobile_from_external: [
        xgettext(
          'Vi har mobilabonnement til alle behov, fra Flex Zero (kr 0,- mnd) til 30 GB data (kr 499,- mnd)',
          'insurance_leads'
        ),
      ],
      dk_power: [
        xgettext('Hudya Power DK intro message', 'dk_power first message'),
      ],

      generic_signup: [gettext('Logg inn eller registrer en ny Hudya konto')],
      se_power_onboarding: [gettext('Power flow first statement')],
      no_eco_adviser: [
        xgettext(
          'I snitt sparer kunder over <strong>10 000 kroner årlig</strong> på å samle tjenestene hos oss',
          'no_eco_adviser'
        ),
      ],
      debt_check: [xgettext('Debt lookup sales pitch', 'debt_check')],
    }

    const loading = this.state.renderedThings.length === 0

    return (
      <div>
        <Form
          error={this.state.error}
          onSubmit={() => this.handleFormSubmit(this.state.fuzzyValue)}
        >
          <div className="chat">
            {dialogStartMessages[this.props.goalFlow] &&
              this.state.renderedThings.length < 1 && (
                <div
                  className="mobile hidden"
                  style={{
                    position: 'relative',
                    top: '42px',
                    left: '-45px',
                  }}
                >
                  <img src="https://wp.hudya.no/wp-content/themes/hudya/assets/img/HudyaIcon.svg"></img>
                </div>
              )}
            {dialogStartMessages[this.props.goalFlow] &&
              dialogStartMessages[this.props.goalFlow].map((text, index) => (
                <div className={'chat-bubble utterance-enter-done'} key={index}>
                  <ReactMarkdown
                    source={text}
                    escapeHtml={false}
                    linkTarget="_blank"
                  />
                </div>
              ))}
            {this.props.firstMessage && (
              <div>
                {this.state.renderedThings.length < 1 && (
                  <div
                    className="mobile hidden"
                    style={{
                      position: 'relative',
                      top: '42px',
                      left: '-45px',
                    }}
                  >
                    <img src="https://wp.hudya.no/wp-content/themes/hudya/assets/img/HudyaIcon.svg"></img>
                  </div>
                )}

                <div className={'chat-bubble utterance-enter-done'}>
                  <ReactMarkdown
                    source={this.props.firstMessage}
                    escapeHtml={false}
                    linkTarget="_blank"
                  />
                </div>
              </div>
            )}
            <div className="container" hidden={!loading}>
              <div className="base-square" />
              <div className="bubble" />
              <div className="loading">
                <div className="dot dot--one" />
                <div className="dot dot--two" />
                <div className="dot dot--three" />
              </div>
              <br />
            </div>

            {this.state.renderedThings.map((field, index) => (
              <div className="chat" key={index}>
                {!field.userResponse &&
                  this.state.renderedThings.length === index + 1 && (
                    <div
                      className="mobile hidden"
                      style={{
                        position: 'relative',
                        top: '42px',
                        left: '-45px',
                      }}
                    >
                      <img src="https://wp.hudya.no/wp-content/themes/hudya/assets/img/HudyaIcon.svg"></img>
                    </div>
                  )}
                <Utterance
                  inputType={field.input_type}
                  utterance={field.utterance}
                  userResponse={field.userResponse}
                  key={index}
                />
              </div>
            ))}
            {this.state.renderedThings.map((field, index) => (
              <InputElements
                key={index}
                visible={
                  this.state.renderedThings.length === index + 1 &&
                  field.input_type != 'iframe'
                }
                inputType={field.input_type}
                suggestedResponses={field.suggested_responses || []}
                hideTextInput={field.force_suggested_responses}
                parameters={field.parameters}
                handleButtonAlternative={this.handleButtonAlternative}
                fuzzyValue={this.props.fuzzyValue}
                handleFieldChange={this.handleFieldChange}
                inputFocusToggle={this.inputFocusToggle}
                resultingState={field.resulting_state}
              />
            ))}
          </div>
        </Form>
      </div>
    )
  }
}
Chat.propTypes = { bobEvent: PropTypes.string }
Chat.defaultProps = { bobEvent: 'HUDYA_WELCOME_MESSAGE' }
export default withRouter(
  connect(
    (state) => ({
      account: state.global.entities.account,
      bob: state.global.bob,
      config: state.global.config,
      credentials: state.global.credentials,
      webapi: state.webapi,
    }),
    (dispatch) => ({
      actions: Object.assign(
        {},
        { dispatch },
        bindActionCreators(
          { setCredentials, resetBob, alertNewMessage, initSoccet },
          dispatch
        )
      ),
    })
  )(Chat)
)
