/**
 * Renders a single product card.
 */

import moment from 'moment'
import React from 'react'
import PropTypes from 'prop-types'
import { Card, Table } from 'semantic-ui-react'

import config from '../../../config'
import PowerSalesCard from '../Power/components/PowerSalesCard'

const DECIMALS_IN_CURRECY = 2
const EMPTY_ARRAY = 0

export default class ProductCard extends React.PureComponent {
  static contextTypes = {
    gettext: PropTypes.func.isRequired,
    xgettext: PropTypes.func.isRequired,
  }
  static propTypes = {
    billing: PropTypes.arrayOf(PropTypes.object).isRequired,
    buttonLinkURI: PropTypes.string.isRequired,
    buttonText: PropTypes.string.isRequired,
    credentials: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    emptyText: PropTypes.array.isRequired,
    header: PropTypes.string.isRequired,
    children: PropTypes.arrayOf(PropTypes.object),
    description: PropTypes.string,
  }

  constructor(props, context) {
    super(props, context)
    this.style = { card: { margin: '1.5em 0' } }
  }

  render() {
    const { gettext } = this.context
    const {
      header,
      description,

      children,
    } = this.props
    const hasChildren =
      children instanceof Array && children.length > EMPTY_ARRAY

    if (!hasChildren) {
      return <PowerSalesCard />
    }
    return (
      <div style={{ marginTop: 50, marginBottom: 50 }}>
        <h1 style={{ color: 'white' }}>{gettext('Dine avtaler hos Hudya')}</h1>
        <Card fluid>
          <Card.Content extra>
            <Card.Header>{header}</Card.Header>
            <Card.Meta />
            <Card.Description>{description}</Card.Description>
          </Card.Content>

          <Card.Content>
            <h2 hidden={this.props.billing.length === EMPTY_ARRAY}>
              {gettext('Locations')}
            </h2>
            {children}

            {/* <div hidden={this.props.billing.length === EMPTY_ARRAY}>
              <br />
              <h2>{gettext('Latest invoices')}</h2>
              <div style={{ maxHeight: 315, overflow: 'auto', width: 'auto' }}>
                <Table fixed singleLine unstackable basic="very">
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>
                        {gettext('Invoice Date')}
                      </Table.HeaderCell>
                      <Table.HeaderCell>{gettext('Amount')}</Table.HeaderCell>
                      <Table.HeaderCell>{gettext('Status')}</Table.HeaderCell>
                      <Table.HeaderCell />
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {this.props.billing
                      .sort((a, b) => {
                        return new Date(a.created) < new Date(b.created)
                      })
                      .map(invoice => {
                        const invoiceURL = `${config.invoice}${invoice.id}`

                        return (
                          <Table.Row key={invoice.id}>
                            <Table.Cell>
                              {moment(invoice.created).format('DD/MM/YYYY')}
                            </Table.Cell>

                            <Table.Cell>
                              {invoice.totalAmount.toFixed(DECIMALS_IN_CURRECY)}{' '}
                              {gettext('NOK')}
                            </Table.Cell>
                            <Table.Cell color="red">
                              {invoice.paid ? (
                                <span style={{ color: 'green' }}>
                                  {gettext('Paid')}
                                </span>
                              ) : (
                                <span style={{ color: 'red' }}>
                                  {gettext('Unpaid')}
                                </span>
                              )}
                            </Table.Cell>
                            <Table.Cell>
                              <a href={invoiceURL} target="blank">
                                {gettext('View invoice')}
                              </a>
                            </Table.Cell>
                          </Table.Row>
                        )
                      })}
                  </Table.Body>
                </Table>
              </div>
            </div> */}
          </Card.Content>
        </Card>{' '}
      </div>
    )
  }
}
ProductCard.defaultProps = {
  billing: [],
}
