import React from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { Label, Grid } from 'semantic-ui-react'

import Chat from '../../../../../components/Chat'

class PowerSalesCard extends React.PureComponent {
  static contextTypes = {
    gettext: PropTypes.func.isRequired,
    xgettext: PropTypes.func.isRequired
  }

  static propTypes = {
    account: PropTypes.object.isRequired,
    credentials: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired
  }

  constructor(props, context) {
    super(props, context)
    this.state = {
      activeExtraText: 'elsert'
    }
  }

  render() {
    const { xgettext, gettext } = this.context

    return (
      <div className="productcard" style={{ paddingBottom: 40 }}>
        <Chat goalFlow="power_onboarding" step="init" />
        <Grid stackable>
          <Grid.Row>
            <Grid.Column width="8">
              <h1> {gettext('Our price addon explained')}</h1>
              <p>
                {gettext(
                  'Detailed explanation text of Hudyas price model on Power'
                )}
              </p>
            </Grid.Column>

            <Grid.Column width="8" style={{ paddingTop: 10 }}>
              <Label
                style={{ cursor: 'pointer' }}
                color="blue"
                size="huge"
                pointing="right"
                onClick={() => this.setState({ activeExtraText: 'elsert' })}
              >
                {gettext('Power sertificate')}
              </Label>
              {gettext('Power sertificate amount')}
              <br />
              <br />
              <Label
                style={{ cursor: 'pointer' }}
                color="blue"
                size="huge"
                pointing="right"
                onClick={() => this.setState({ activeExtraText: 'handel' })}
              >
                {gettext('Power trading')}
              </Label>
              {gettext('Power trading amount')}
              <br />
              <br />
              <Label
                style={{ cursor: 'pointer' }}
                color="blue"
                size="huge"
                pointing="right"
                onClick={() => this.setState({ activeExtraText: 'påslag' })}
              >
                {gettext('Hudyas addon')}
              </Label>
              {gettext('Hudya addon amount')}
              <br />
              <br />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row style={{}}>
            <Grid.Column>
              {this.state.activeExtraText === 'elsert' && (
                <div>
                  <h3>{gettext('Power sertificate')}</h3>
                  <p>{gettext('Power sertificate markup explained.')}</p>
                </div>
              )}
              {this.state.activeExtraText === 'handel' && (
                <div>
                  <h3>{gettext('Power trading')}</h3>
                  <p>{gettext('Power trading markup explained')}</p>
                </div>
              )}
              {this.state.activeExtraText === 'påslag' && (
                <div>
                  <h3> {gettext('Hudyas addon')}</h3>
                  <p>{gettext('Hudyas addons explained')}</p>
                </div>
              )}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    )
  }
}

export default withRouter(
  connect(
    state => ({
      account: state.global.entities.account,
      config: state.global.config,
      credentials: state.global.credentials,
      webapi: state.webapi
    }),
    dispatch => ({
      actions: bindActionCreators({}, dispatch),
      apiActions: bindActionCreators({}, dispatch),
      dispatch
    })
  )(PowerSalesCard)
)
