import { frendeAPI } from '../../webapi/frende';

const initialState = {
  lastUpdated: null,
  allSubscriptions: [],
  subscriptions: [],
  sync: false,
  error: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case frendeAPI.events.frendeSubscriptions.actionFail:
      console.log('Frende: ', action.error);
      return Object.assign({}, state, {
        subscriptions: Object.assign([], state.subscriptions, []),
        sync: true,
        error: true,
      });
    case frendeAPI.events.frendeSubscriptions.actionSuccess:
      let subscriptions = [];
      let allSubscriptions = [];
      const lastUpdated = action.data.lastUpdated || null;
      if (action.data) {
        if (action.data.result && action.data.result.length > 0) {
          allSubscriptions = action.data.result.map((i, index) => ({
            id: index,
            subscriptionName: i.productType,
            count: i.numberOfItems,
            status: i.status,
          }));
          subscriptions = allSubscriptions.filter((f) => f.status === 'Active');
        }
      }
      return Object.assign({}, state, {
        allSubscriptions: Object.assign([], state.allSubscriptions, [
          ...allSubscriptions,
        ]),
        subscriptions: Object.assign([], state.subscriptions, [
          ...subscriptions,
        ]),
        lastUpdated,
        sync: true,
        error: false,
      });

    default:
      return state;
  }
}
