import './Polyfills/ArrayIncludes';
import './Polyfills/StringIncludes';

import React from 'react';
import ReactDOM from 'react-dom';
import moment from 'moment';
// Import all extra locales the app should be able to switch to (english is included).
import 'moment/locale/nb';
import 'moment/locale/se';
import 'moment/locale/da';
import jwtDecode from 'jwt-decode';
import { hotjar } from 'react-hotjar';

import { version } from '../package.json';

import { unregister } from './registerServiceWorker';
import createRoutes from './routes';
import store from './store/configureStore';
import AppProvider from './components/AppProvider';
import { transifexAPI } from './store/webapi/transifex';
import { configureClient } from './store/global/contentful/client';
import { BobApi } from './store/webapi/bobBrain';
import { setDefaultLocale } from './store/global/config';
import { resetCredentials } from './store/global/credentials';
import { legalEntityAPI } from './store/webapi/legalEntity';
import variables from './config';

hotjar.initialize(variables.HOTJARID, variables.HOTJARSNIPPET);

const run = () => {
  console.log(`Portal: ${version}`);
  const routes = createRoutes(store);
  const brainIds = localStorage.getItem('brainSessionId');
  const render = () => {
    // Unregister service worker because it caches too much!
    unregister();

    // If we have an access token, make sure it's valid!
    const { accessToken } = store.getState().global.credentials;
    const jwtFromLocalStore = localStorage.getItem('brainSessionJwt');
    const domaineTopLevel = window.location.hostname.match(/\w+$/)[0];
    const domaineLangMap = {
      no: 'nb_NO',
      se: 'sv_SE',
      dk: 'da_DK',
    };
    store.dispatch(setDefaultLocale(domaineLangMap[domaineTopLevel]));
    if (accessToken) {
      // store.dispatch(
      //   authAPI.actions.verifyToken(
      //     {},
      //     { body: JSON.stringify({ token: accessToken }) }
      //   )
      // )
      const { expires } = store.getState().global.credentials;

      if (expires && new Date(Date.now()) >= new Date(expires * 1000)) {
        store.dispatch(resetCredentials());
        const logout = () => ({ type: 'LOGOUT' });

        store.dispatch(logout());
      }
      store.dispatch(
        legalEntityAPI.actions.entity.sync({
          id: store.getState().global.credentials.id,
        }),
      );
    }
    if (jwtFromLocalStore) {
      const decodedJwt = jwtDecode(jwtFromLocalStore);
      if (
        decodedJwt &&
        new Date(Date.now()) >= new Date(decodedJwt.exp * 1000)
      ) {
        localStorage.removeItem('brainSessionJwt');
      }
    }
    Promise.resolve(
      store.dispatch(
        BobApi.actions.initBob.post('', {
          vertex: { id: JSON.parse(brainIds) },

          content_type: 'init',
        }),
      ),
    ).then(
      Promise.resolve(store.dispatch(configureClient())).then(
        Promise.resolve(
          store
            .dispatch(
              transifexAPI.actions.translation.sync({
                locale: store.getState().global.config.defaultLocale,
              }),
            )
            .then(() => {
              moment.locale(store.getState().global.config.defaultLocale);
            }),
        )
          .then(() => {
            ReactDOM.render(
              <AppProvider
                plural={(n) => (n > 1 ? 2 : 1)}
                routes={routes}
                store={store}
                translations={() =>
                  store.getState().webapi.transifex.translation.data
                }
              />,
              document.getElementById('root'),
            );
          })
          .catch(() => {
            ReactDOM.render(
              <AppProvider
                plural={(n) => (n > 1 ? 2 : 1)}
                routes={routes}
                store={store}
                translations={() =>
                  store.getState().webapi.transifex.translation.data
                }
              />,
              document.getElementById('root'),
            );
          }),
      ),
    );
  };

  render();
};

Promise.all([
  new Promise((resolve) => {
    if (window.addEventListener) {
      window.addEventListener('DOMContentLoaded', resolve);
    } else {
      window.attachEvent('onload', resolve);
    }
  }),
]).then(run);
