import React from "react"
import PropTypes from "prop-types"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { Grid, Container } from "semantic-ui-react"

import { legalEntityAPI } from "../../../store/webapi/legalEntity"

import { setCredentials } from "../../../store/global/credentials"

import jwtDecode from "jwt-decode"

import SvgDivider from "../../../components/SvgDivider"
import TopSection from "./components/TopSection"
import Features from "./components/Features"
import StepCard from "./components/StepCard"
import HelpCard from "./components/HelpCard"
import SubscriptionSection from "./components/SubscriptionSection"

class MobileOverview extends React.Component {
  static contextTypes = { gettext: PropTypes.func.isRequired }

  static propTypes = {}

  constructor(props, context) {
    super(props, context)
    this.logUserIn = this.logUserIn.bind(this)
    this.state = { activeIndex: -1 }
  }
  logUserIn(jwt) {
    if (!jwt) {
      return
    }
    this.props.actions.setCredentials(jwt)

    const decodedJWT = jwtDecode(jwt)

    this.props.actions.dispatch(
      legalEntityAPI.actions.entity.sync({ id: decodedJWT.sub })
    )
  }
  handleClick = (e, titleProps) => {
    const { index } = titleProps
    const { activeIndex } = this.state
    const newIndex = activeIndex === index ? -1 : index

    this.setState({ activeIndex: newIndex })
  }
  render() {
    const params = new URLSearchParams(window.location.search)
    const { gettext } = this.context
    const flow = params.get("flow")
    const jwt = localStorage.getItem("brainSessionJwt")
    const productIncludesStatements = [
      gettext("Telenor 4G"),
      gettext("No binding"),
      gettext("Use in EU"),
      gettext("Free BankID"),
      gettext("Free speed")
    ]

    return (
      <div className="mobile-overview">
        <TopSection />
        <SvgDivider path="M0,22h1080V8.067c0,0-539.279-19.53-1080,2.186V22z" />
        {/* PLANS CARDS */}

        <section className="mobile-information">
          {/* INCLUDE */}
          <div className="mobile-includes  mobile hidden">
            <Container textAlign="center">
              <p className="line-back-title">
                <span>{gettext("Our products include")}</span>
              </p>
              <div className="ui stackable doubling five column grid">
                {productIncludesStatements.map((item, index) => (
                  <ProductincludesElements statement={item} key={index} />
                ))}
              </div>
            </Container>
          </div>
          <SubscriptionSection />
          <div className="mobile-includes  mobile only">
            <Container textAlign="center">
              <p className="line-back-title">
                <span>{gettext("Our products include")}</span>
              </p>
              <div className="ui stackable doubling five column grid">
                {productIncludesStatements.map((item, index) => (
                  <ProductincludesElements statement={item} key={index} />
                ))}
              </div>
            </Container>
          </div>
          <Features />

          <StepCard />
          {/* FAQ ACCORDIONS */}
          {/* <div className="mobile-faq-accordions">
            <Container textAlign="center">
              <p className="faq-accordions-title">
                <i>
                  All you need to know about <b>mobile</b>
                </i>
              </p>
              <Grid stackable centered>
                <Grid.Row>
                  <Grid.Column
                    className="twelve wide computer"
                    textAlign="left"
                  >
                    <Accordion>
                      <Card fluid>
                        <div className="content">
                          <Accordion.Title
                            className="ui"
                            active={this.activeIndex === 0}
                            index={0}
                            onClick={this.handleClick}
                          >
                            Why do I get SMS that I am approaching the amount
                            limit abroad? <Icon name="chevron down" />
                          </Accordion.Title>
                          <Accordion.Content
                            active={this.state.activeIndex === 0}
                          >
                            <p>
                              Lorem ipsum dolor sit amet, consectetur adipiscing
                              elit. Suspendisse sagittis laoreet odio ut
                              finibus. Class aptent taciti sociosqu ad litora
                              torquent per conubia nostra, per inceptos
                              himenaeos. Pellentesque quis ante porttitor,
                              ornare mauris sed, imperdiet metus. Vestibulum
                              lobortis quam blandit ipsum porttitor, ut varius
                              lorem cursus. Aenean quis convallis lacus. Aliquam
                              congue lorem sed malesuada lacinia. Aenean semper
                              sodales faucibus. Nulla posuere nulla sed arcu
                              imperdiet vestibulum. Nam hendrerit luctus
                              placerat. Vivamus elementum pellentesque nunc sed
                              scelerisque. In rutrum eleifend hendrerit. Vivamus
                              vel orci sollicitudin, varius tortor ut, laoreet
                              ante. Quisque dictum facilisis convallis.
                            </p>
                          </Accordion.Content>
                        </div>
                      </Card>
                    </Accordion>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
              <p>
                More questions?{' '}
                <a href="#">
                  <strong>Check our help center</strong>
                </a>
              </p>
            </Container>
          </div> */}

          {/* NEED HELP */}
          <HelpCard />
        </section>
      </div>
    )
  }
}
const ProductincludesElements = props => (
  <Grid.Column>
    <p>
      <img src={process.env.PUBLIC_URL + "/assets/icon-check-mark.svg"} />{" "}
      <b>{props.statement}</b>
    </p>
  </Grid.Column>
)
export default connect(
  state => ({
    login: state.core.auth.login,
    credentials: state.global.credentials
  }),
  dispatch => ({
    actions: Object.assign(
      {},
      { dispatch },
      bindActionCreators({ setCredentials }, dispatch)
    )
  })
)(MobileOverview)

// function Header(props) {
//   return (
//     <div className="main-heading">
//       <h1>
//         Betal for strømmen du bruker, og <strong>ikke noe mer</strong>
//       </h1>
//     </div>
//   )
// }
