import React from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Grid, Container, Image } from 'semantic-ui-react'
import { Redirect } from 'react-router-dom'
import ReactMarkdown from 'react-markdown/with-html'
//import { accountAPI } from '../../../store/webapi/account'
import { legalEntityAPI } from '../../../store/webapi/legalEntity'

import { setCredentials } from '../../../store/global/credentials'
import styles from './mobile.module.css'
import jwtDecode from 'jwt-decode'
import faq from './faq'
import Chat from '../../../components/Chat'
import ProductLinks from '../components/ProductLinks'
import StepCard from './components/StepCard'
import SvgDivider from '../../../components/SvgDivider'
import ChatContainer from '../../../components/ChatContainer'

class Mobile extends React.PureComponent {
  static contextTypes = {
    gettext: PropTypes.func.isRequired,
    xgettext: PropTypes.func.isRequired,
  }

  static propTypes = {}

  constructor(props, context) {
    super(props, context)
    this.logUserIn = this.logUserIn.bind(this)
    document.title = context.xgettext('Mobil - Hudya', 'Mobile page title')
  }
  logUserIn(jwt) {
    if (!jwt) {
      return
    }
    this.props.actions.setCredentials(jwt)

    const decodedJWT = jwtDecode(jwt)

    this.props.actions.dispatch(
      //  accountAPI.actions.account.sync({ id: decodedJWT.sub })
      legalEntityAPI.actions.entity.sync({ id: decodedJWT.sub })
    )
  }
  render() {
    const params = new URLSearchParams(window.location.search)
    const { gettext, xgettext } = this.context
    const flow = params.get('flow')
    const jwt = localStorage.getItem('brainSessionJwt')

    // if (jwt) {
    //   this.logUserIn(jwt)
    // }

    // if (this.props.credentials.isAuthenticated) {
    //   return (
    //     <Redirect
    //       to={{
    //         pathname: '/welcome',
    //         state: { from: '/' }
    //       }}
    //     />
    //   )
    // }
    return (
      <div className={styles['public-mobile']}>
        <ChatContainer
          flow={flow || 'mobile_onboarding'}
          step="init_with_fixed_start"
          chatH1={xgettext(
            'Hudya <strong>Mobile</strong>',
            'mobile main header'
          )}
          chatH2={gettext('Mobile sales heading')}
          chatSideBody={gettext('Mobile sales text')}
          firstMessage={xgettext(
            'Vi har mobilabonnement til alle behov, fra Flex Zero (kr 0,- mnd) til 30 GB data (kr 499,- mnd)',
            'mobile_onboarding'
          )}
        />

        {/* <SvgDivider path="M0,22h1080V8.067c0,0-539.279-19.53-1080,2.186V22z" />
        <section className="mobile-information">
          <StepCard></StepCard>
        </section> */}
        <ProductLinks />
        <div className={styles['mobile-faq']}>
          <Container>
            <div className={styles['svg-curve']}>
              <svg
                version="1.1"
                fill="#FFFFF7"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                viewBox="0 0 1080 65"
                preserveAspectRatio="none"
              >
                <path d="M0,45.069C671.161,45.478,1080,0,1080,0v65H0V45.069z" />
              </svg>
            </div>
            <Grid stackable>
              <Grid.Row textAlign="left">
                <Grid.Column width="7">
                  <h3 className={styles['section-title']}>
                    <ReactMarkdown
                      source={xgettext(
                        'All you need to know about <strong>mobile</strong>',
                        'mobile faq header'
                      )}
                      escapeHtml={false}
                    />
                  </h3>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row textAlign="left">
                {faq.map((f) => {
                  return <Faq faq={f} key={f.name} />
                })}
              </Grid.Row>
            </Grid>
          </Container>
        </div>
      </div>
    )
  }
}

export default connect(
  (state) => ({
    login: state.core.auth.login,
    credentials: state.global.credentials,
  }),
  (dispatch) => ({
    actions: Object.assign(
      {},
      { dispatch },
      bindActionCreators({ setCredentials }, dispatch)
    ),
  })
)(Mobile)

function Faq(props) {
  return (
    <Grid.Column width="8">
      <a href={props.faq.url}>{props.faq.name}</a>
    </Grid.Column>
  )
}
// function Header(props) {
//   return (
//     <div className="main-heading">
//       <h1>
//         Betal for strømmen du bruker, og <strong>ikke noe mer</strong>
//       </h1>
//     </div>
//   )
// }
