import React from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import renderHTML from 'react-render-html'
import { Container, Grid } from 'semantic-ui-react'

import '../../../utils/string' // eslint-disable-line import/no-unassigned-import
import { invoiceAPI } from '../../../store/webapi/invoice'

import ProductCard from '../components/ProductCard'
import ProductCardItem from '../components/ProductCardItem'

class Power extends React.PureComponent {
  static contextTypes = {
    gettext: PropTypes.func.isRequired,
    xgettext: PropTypes.func.isRequired
  }

  static propTypes = {
    account: PropTypes.object.isRequired,
    credentials: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired
  }

  constructor(props, context) {
    super(props, context)
    this.styles = {
      container: {
        marginLeft: 0,
        marginRight: 0
      }
    }
  }
  componentWillMount() {
    const { dispatch } = this.props

    dispatch(invoiceAPI.actions.invoice.get())
  }

  render() {
    if (this.props.webapi.subscriptions.subscriptionsList.loading) {
      return null
    }
    const { gettext, xgettext } = this.context

    const meteringPoints = this.props.subscriptions || {}

    const meteringPointProductItems = meteringPoints.map((item, index) => {
      let address = ''
      if (item.asset.address)
        address = `${item.asset.address.addressLines[0]} ${item.asset.address.code} ${item.asset.address.place}`.trim()

      if (address === '') {
        address = xgettext(
          'Unknown address',
          'Text which is displayed if unable to construct a proper address for the metering point ID.'
        )
      }

      return (
        <ProductCardItem
          addressText={address}
          key={index}
          meteringPointId={item.asset.foreignId}
          meteringPointStatus={
            String(item.state).toLocaleLowerCase() === 'active'
              ? gettext('Active')
              : gettext('Awaiting confirmation')
          }
        />
      )
    })

    return (
      <Container textAlign="center">
        <Grid
          stackable
          style={{ justifyContent: 'center' }}
          verticalAlign="middle"
        >
          <Grid.Row textAlign="left">
            <Grid.Column width="twelve">
              <ProductCard
                billing={this.props.invoices}
                buttonLinkURI="/products/power/new-subscription"
                buttonText={xgettext(
                  'Add location',
                  'Text on button for adding a new metering point.'
                )}
                credentials={this.props.credentials}
                dispatch={this.props.dispatch}
                emptyText={renderHTML(
                  xgettext(
                    'Fair electricity prices. <strong>Always.<br />Hudya Power is easy, fair and hassle-free.<br /></strong>You get a fair deal and more peace of mind.',
                    'Text displayed if the logged in user has no power subscriptions available.'
                  )
                )}
                header={xgettext('Power', 'The header text on the power card.')}
              >
                {meteringPointProductItems}
              </ProductCard>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <br />
        <br />
      </Container>
    )
  }
}

export default withRouter(
  connect(
    state => ({
      webapi: state.webapi,
      account: state.global.entities.account,
      config: state.global.config,
      credentials: state.global.credentials,
      invoices: state.global.entities.products.power.invoices,

      subscriptions: state.global.entities.products.power.power
    }),
    dispatch => ({
      actions: bindActionCreators({}, dispatch),
      apiActions: bindActionCreators({}, dispatch),
      dispatch
    })
  )(Power)
)
