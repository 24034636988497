import React from 'react'
import PropTypes from 'prop-types'
import ReactMarkdown from 'react-markdown/with-html'
import Chat from '../../../../../components/Chat/modal'

import { Grid, Container, Card, Button } from 'semantic-ui-react'
import styles from './helpCard.module.css'
const HelpCard = (props, context) => {
  const { gettext, xgettext } = context
  return (
    <div className="mobile-information">
      <Container>
        <Card fluid className="help-card big-help">
          <div className="content">
            <Grid stackable doubling>
              <div className="ten wide tablet seven wide computer column">
                <h4 className="help-title">
                  {gettext('Do you')} <strong>{gettext('need help?')}</strong>
                </h4>
                <p>
                  {gettext(
                    ' Do you want to become a Telipol customer, but are unsure which subscription is the best?'
                  )}
                </p>
                <Chat
                  goalFlow="mobile_onboarding"
                  step="mobile_call_me"
                  buttonText={gettext('Click here for support')}
                  buttonSize="huge"
                />
              </div>
              <div className="six wide tablet eight wide computer column right aligned">
                <img
                  src={process.env.PUBLIC_URL + '/media/section-help-table.png'}
                />
              </div>
            </Grid>
          </div>
        </Card>
      </Container>
    </div>
  )
}

HelpCard.contextTypes = {
  gettext: PropTypes.func.isRequired,
  xgettext: PropTypes.func.isRequired
}

export default HelpCard
