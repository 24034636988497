import React from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Grid, Container, Tab, Header } from 'semantic-ui-react'
import { Redirect } from 'react-router-dom'
import ReactMarkdown from 'react-markdown/with-html'
//import { accountAPI } from '../../../store/webapi/account'
import { legalEntityAPI } from '../../../store/webapi/legalEntity'

import { setCredentials } from '../../../store/global/credentials'
import styles from './mobile.module.css'
import jwtDecode from 'jwt-decode'
import faq from './faq'
import Chat from '../../../components/Chat'
import ProductLinks from '../components/ProductLinks'
import StepCard from './components/StepCard'
import SvgDivider from '../../../components/SvgDivider'
import Features from './components/Features'
import TopSaleSection from './components/MoreInfo/components/TopSaleSection'
import panesNoFlex from './components/MoreInfo/components/DataTables/noFlex'

class MobilePrices extends React.PureComponent {
  static contextTypes = {
    gettext: PropTypes.func.isRequired,
    xgettext: PropTypes.func.isRequired
  }

  static propTypes = {}

  constructor(props, context) {
    super(props, context)

    document.title = context.xgettext(
      'Mobilabonnement - Øvrige priser',
      'Mobile price page title'
    )
  }

  render() {
    const params = new URLSearchParams(window.location.search)
    const { gettext, xgettext } = this.context
    const flow = params.get('flow')
    const productIncludesStatements = [
      gettext('Telenor 4G'),
      gettext('No binding'),
      gettext('Use in EU'),
      gettext('Free BankID'),
      gettext('Free speed')
    ]

    return (
      <div className={styles['public-mobile']}>
        <Container>
          <div
            className={styles['public-mobile-header']}
            style={{ position: 'relative' }}
          >
            <p>
              <img
                className={styles['mobile-logo']}
                src="https://3w0ze1378d2118joo61k25p1-wpengine.netdna-ssl.com/wp-content/themes/hudya-norway/assets/img/hudya-logo-light.svg"
              />
              <i>{gettext('Life simplified')}</i>
            </p>
            <h1>
              <ReactMarkdown
                source={xgettext(
                  'Hudya <strong>Mobil</strong> øvrige priser',
                  'mobile price h1'
                )}
                escapeHtml={false}
              />
            </h1>

            {/* <img
            className="aconto-cards-image"
            src={process.env.PUBLIC_URL + '/assets/cards.png'}
          /> */}
          </div>
        </Container>
        {/* <SvgDivider path="M0,22h1080V8.067c0,0-539.279-19.53-1080,2.186V22z" />
        <section className="mobile-information">
          <StepCard></StepCard>
        </section> */}
        {/* <SvgDivider path="M0,22h1080V8.067c0,0-539.279-19.53-1080,2.186V22z" />
        <section className="mobile-information">
          <div className="mobile-includes  mobile hidden">
            <Container textAlign="center">
              <p className="line-back-title">
                <span>{gettext("Our products include")}</span>
              </p>
              <div className="ui stackable doubling five column grid">
                {productIncludesStatements.map((item, index) => (
                  <ProductincludesElements statement={item} key={index} />
                ))}
              </div>
            </Container>
          </div>
          <StepCard style={{ marginBottom: 200 }}></StepCard>
        </section> */}
        <div className={styles['mobile-prices']}>
          <Container>
            {
              <Tab
                menu={{
                  attached: false,
                  tabular: false,
                  stackable: true
                }}
                panes={panesNoFlex}
              />
            }
          </Container>
        </div>
        <ProductLinks />
        <div className={styles['mobile-faq']}>
          <div className={styles['svg-curve']}>
            <svg
              version="1.1"
              fill="#FFFFF7"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              viewBox="0 0 1080 65"
              preserveAspectRatio="none"
            >
              <path d="M0,45.069C671.161,45.478,1080,0,1080,0v65H0V45.069z" />
            </svg>
          </div>
          <Container>
            <Grid stackable>
              <Grid.Row textAlign="left">
                <Grid.Column width="7">
                  <h3 className={styles['section-title']}>
                    <ReactMarkdown
                      source={xgettext(
                        'All you need to know about <strong>mobile</strong>',
                        'mobile faq header'
                      )}
                      escapeHtml={false}
                    />
                  </h3>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row textAlign="left">
                {faq.map(f => {
                  return <Faq faq={f} key={f.name} />
                })}
              </Grid.Row>
            </Grid>
          </Container>
        </div>
      </div>
    )
  }
}

export default connect(
  state => ({
    login: state.core.auth.login,
    credentials: state.global.credentials
  }),
  dispatch => ({
    actions: Object.assign(
      {},
      { dispatch },
      bindActionCreators({ setCredentials }, dispatch)
    )
  })
)(MobilePrices)

function Faq(props) {
  return (
    <Grid.Column width="8">
      <a href={props.faq.url}>{props.faq.name}</a>
    </Grid.Column>
  )
}
const ProductincludesElements = props => (
  <Grid.Column>
    <p style={{ color: '#ffffff' }}>
      <img src={process.env.PUBLIC_URL + '/assets/icon-check-mark.svg'} />{' '}
      <b>{props.statement}</b>
    </p>
  </Grid.Column>
)
