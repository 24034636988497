import React from 'react'
import PropTypes from 'prop-types'
import renderHTML from 'react-render-html'

import { Grid, Container, Card } from 'semantic-ui-react'
import styles from './stepCard.module.css'
const StepCard = (props, context) => {
  const { gettext, xgettext } = context
  return (
    <div className="mobile-switch">
      <Container>
        <Card fluid>
          <div className="content">
            <Grid stackable doubling>
              <Grid.Row>
                <div className="six wide tablet column">
                  <h4 className="switch-title">
                    {renderHTML(gettext('So easy to <br /> switch to us!'))}
                  </h4>
                  <img
                    className="phone-img"
                    src={process.env.PUBLIC_URL + '/media/mobile-phone-pic.png'}
                  />
                </div>
                <div className="ten wide tablet column">
                  <div className="switch-item">
                    <div className="switch-icon">
                      <img
                        src={process.env.PUBLIC_URL + '/media/apps.svg'}
                        width="20"
                      />
                    </div>
                    <h6 className="switch-item-title">
                      {' '}
                      {gettext('Select subscription')}
                    </h6>
                    <p>
                      {gettext(
                        'Choose one of our subscriptions to suit your consumption'
                      )}
                    </p>
                  </div>
                  <div className="switch-item">
                    <div className="switch-icon">
                      <img
                        src={
                          process.env.PUBLIC_URL + '/media/incoming-call.svg'
                        }
                        width="20"
                      />
                    </div>
                    <h6 className="switch-item-title">
                      {gettext(
                        'Choose to include your number or get a new number'
                      )}
                    </h6>
                    <p>
                      {renderHTML(
                        gettext(
                          'Transfer: It is free to include your current mobile number with Telipol. We arrange for termination with your previous supplier'
                        )
                      )}
                    </p>
                    <p>
                      {renderHTML(
                        gettext(
                          'New number: You can also choose to get a new mobile number with us - remember that you have to cancel your self with your previous supplier'
                        )
                      )}
                    </p>
                  </div>
                  <div className="switch-item">
                    <div className="switch-icon">
                      <img
                        src={process.env.PUBLIC_URL + '/media/sim-card.svg'}
                        width="20"
                      />
                    </div>
                    <h6 className="switch-item-title">
                      {gettext('We will send you a new SIM card')}
                    </h6>
                    <p>
                      {gettext(
                        'To switch to Telipol you need a new SIM card from us - we send out the SIM card so that it is with you within 1-3 business days. The SIM card fits in all mobile phones'
                      )}
                    </p>
                  </div>
                </div>
              </Grid.Row>
            </Grid>
          </div>
        </Card>
      </Container>
    </div>
  )
}

StepCard.contextTypes = {
  gettext: PropTypes.func.isRequired,
  xgettext: PropTypes.func.isRequired
}

export default StepCard
